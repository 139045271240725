@function toRem($a) {
    @return ($a/37.5) + rem;
}
.draw-lottery {
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .not-start-status {
        display: flex;
        flex-direction: column;
        position: relative;
        .close {
            width: toRem(21);
            height: toRem(21);
            position: absolute;
            z-index: 2;
            right: 0;
        }
       .img{
            width: toRem(310);
            height: toRem(894/2);
            background-size: 100% 100%;
            overflow:hidden;
            .notice{
                display: flex;
                justify-content: center;
                align-items: center;
                width:toRem(366/2);
                height: toRem(18);
                margin:0 auto;
                margin-top: toRem(268/2);
                img{
                    width: toRem(12);
                    height: toRem(12);
                    margin-right: toRem(10);
                }
                .adm-notice-bar{
                    width: 100%;
                    font-size: toRem(11);
                }
                .adm-swiper{
                    height: 100%;
                    font-size: toRem(11);
                }
            }
        }
       .num-rules {
            color: #fff;
            text-align: center;
            margin:toRem(50) 0;
            p:nth-child(1) {
                text-align: center;
                font-size: toRem(12);
                margin-bottom: toRem(10);
            }
            p:nth-child(2) {
                font-size: toRem(14);
                text-decoration:underline
            }
        }
    }
    .no-prize-status {
        display: flex;
        flex-direction: column;
        align-items: center;
        .close {
            width: toRem(21);
            height: toRem(21);
            margin-right: toRem(37.5);
            position: absolute;
            z-index: 2;
            right: 0;
        }
        img {
            width: toRem(330);
            height: toRem(763/2);
        }
        div {
            width: toRem(416/2);
            background: #ff8e6a;
            border-radius: toRem(20);
            color: #fff;
            text-align: center;
            line-height: toRem(79/2);
            font-size: toRem(18);
            margin:toRem(35) 0 toRem(70) 0;
        }
    }
    .success-status{
        display: flex;
        flex-direction: column;
        align-items: center;
        .close {
            width: toRem(21);
            height: toRem(21);
            margin-right: toRem(37.5);
            position: absolute;
            z-index: 2;
            right: 0;
        }
        .integral{
            background-size: 100% 100%;
            height:toRem(746/2);
            width: toRem(659/2);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            p:nth-child(1){
                margin-bottom: toRem(4);
                font-size: toRem(15);
                color: #F1320E;
            }
            p:nth-child(2){
                margin-bottom: toRem(50);
                font-size: toRem(12);
                color: #5E2F34;
            }
        }
        .btn{
            width: toRem(416/2);
            background: #ff8e6a;
            border-radius: toRem(20);
            color: #fff;
            text-align: center;
            line-height: toRem(79/2);
            font-size: toRem(18);
            margin:toRem(35) 0 toRem(70) 0;
        }
    }
}
