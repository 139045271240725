@function toRem($a) {
    @return ($a/37.5) + rem;
}

.community {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y: scroll;
    // padding-bottom: toRem(50);
    .cate {
        display: flex;
        align-items: center;
        padding:toRem(20) toRem(14) toRem(16) toRem(14);
        overflow-x: scroll;
        position: sticky;
        background-color: #F4F7FC;
        left: 0;
        top: 0;
        z-index: 3;
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
        div {
            margin-right: toRem(16);
            font-size: toRem(16);
            color: #bfc5ca;
            padding-bottom: toRem(4);
            flex: none;
            &:last-child{
                margin:0;
            }
        }
        .active {
            color: #333;
            font-weight: bold;
            position: relative;
            &:before {
                content: "";
                width: toRem(24);
                height: toRem(3);
                border-radius: toRem(3);
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                background-color: #718cfb;
            }
        }
    }
    .list {
        display: flex;
        padding:0 toRem(14);
        .left {
            margin-right: toRem(7);
            .item {
                background-color: #fff;
                border-radius: toRem(8);
                margin-bottom: toRem(7);
                padding-bottom: toRem(10);
                width: toRem(170);
                .cover {
                    width: toRem(170);
                    height: toRem(190);
                    border-radius: toRem(8) toRem(8) 0 0;
                    object-fit: cover;
                }
                .title {
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-top: toRem(5);
                    padding: 0 toRem(8);
                }
                .date {
                    margin-top: toRem(10);
                    display: flex;
                    justify-content: space-between;
                    padding: 0 toRem(8);
                    color: #999;
                    font-size: toRem(12);
                    div:nth-child(1) {
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(20);
                            height: toRem(20);
                            border-radius: 50%;
                            margin-right: toRem(6);
                        }
                        span {
                            width: toRem(70);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(26.78/2);
                            height: toRem(24/2);
                            margin-right: toRem(9/2);
                        }
                    }
                }
            }
        }
        .right {
            .item {
                background-color: #fff;
                border-radius: toRem(8);
                margin-bottom: toRem(7);
                padding-bottom: toRem(10);
                width: toRem(170);
                .cover {
                    width: toRem(170);
                    height: toRem(190);
                    border-radius: toRem(8) toRem(8) 0 0;
                    object-fit: cover;
                }
                .title {
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-top: toRem(5);
                    padding: 0 toRem(8);
                }
                .date {
                    margin-top: toRem(10);
                    display: flex;
                    justify-content: space-between;
                    padding: 0 toRem(8);
                    color: #999;
                    font-size: toRem(12);
                    div:nth-child(1) {
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(20);
                            height: toRem(20);
                            border-radius: 50%;
                            margin-right: toRem(6);
                        }
                        span {
                            width: toRem(70);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(26.78/2);
                            height: toRem(24/2);
                            margin-right: toRem(9/2);
                        }
                    }
                }
            }
        }
       
    }
    .no{
        margin-top: toRem(20);
        margin-bottom: toRem(20);
        text-align: center;
        color: #ccc;
        font-size: toRem(12);
    }
}
