@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content {
    padding: 0 toRem(14);
    overflow: hidden;
    .userInfo {
        margin-top: toRem(20);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: toRem(20);
        div:nth-child(1) {
            display: flex;
            align-items: center;
            img {
                width: toRem(50);
                height: toRem(50);
                border-radius: 50%;
            }
            span {
                font-size: toRem(16);
                color: #3b3843;
                font-weight: bold;
                margin-left: toRem(10);
            }
        }
        div:nth-child(2){
            width: toRem(83);
            height: toRem(30);
            border-radius: toRem(15);
            color: #fff;
            color: #2A5EFF;
            line-height: toRem(30);
            text-align: center;
            background-color: #DBE4FF;
        }
    }
    .money {
        height: toRem(112);
        margin-bottom: toRem(13);
        background-size: 100% 100%;
        padding-left: toRem(13);
        overflow: hidden;
        position: relative;
        .withdrawal_money {
            color: #fff;
            margin-top: toRem(15);
            div:nth-child(1) {
                display: flex;
                align-items: center;
                font-size: toRem(14);
                img {
                    width: toRem(16);
                    height: toRem(13.5);
                }
                span {
                    margin-left: toRem(8);
                }
            }
            div:nth-child(2) {
                margin-top: toRem(20);
                font-weight: bold;
                span:nth-child(1) {
                    font-size: toRem(23);
                }
                span:nth-child(2) {
                    font-size: toRem(34);
                }
            }
        }
        .right {
            position: absolute;
            z-index: 2;
            right: toRem(-28);
            top: 50%;
            transform: translateY(-50%);
            width: toRem(56);
            box-sizing: border-box;
            height: toRem(26);
            display: flex;
            align-items: center;
            padding-left: toRem(9);
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: toRem(10) 0 0 toRem(10);
        }
    }
    .func {
        background-color: #fff;
        border-radius: toRem(8);
        padding: toRem(15) toRem(13);
        padding-bottom: 0;
        .title {
            font-size: toRem(16);
            font-weight: bold;
            color: #333;
            margin-bottom: toRem(20);
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: toRem(41);
                margin-bottom: toRem(20);
                &:nth-child(4n+0) {
                    margin-right: 0;
                }
                img {
                    width: toRem(27.5);
                    height: toRem(27.5);
                }
                span {
                    color: #333;
                    font-size: toRem(12);
                    margin-top: toRem(5);
                }
            }
        }
    }
}
.feedback {
    overflow: hidden;
    padding: 0 toRem(13);
    background-color: #f7f9fa;
    .title {
        font-size: toRem(18);
        font-weight: bold;
        color: #333;
        text-align: center;
        margin-top: toRem(20);
        margin-bottom: toRem(10);
    }
    .adm-text-area {
        background-color: #fff;
    }
    .put {
        padding: toRem(10);
        background-color: #fff;
        border-radius: toRem(8);
    }
    .btn {
        margin: toRem(20) 0;
        line-height: toRem(41);
        text-align: center;
        color: #fff;
        font-size: toRem(14);
        background-color: #3b6cf5;
        border-radius: toRem(21);
    }
}
.customerServiceCode {
    overflow: hidden;
    padding: 0 toRem(13);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: toRem(15);
    div:nth-child(1) {
        font-size: toRem(18);
        font-weight: bold;
        color: #333;
        text-align: center;
        margin-top: toRem(20);
        margin-bottom: toRem(10);
    }
    div:nth-child(2) {
        font-size: toRem(14);
        color: #3b3843;
    }
    img {
        width: toRem(225);
        height: toRem(218);
    }
}
