@function toRem($a) {
    @return ($a/37.5)+rem;
}

.content {
    padding: 0 toRem(14);
    color: #333;
    overflow: hidden;
    .type {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: toRem(50);
        z-index: 2;
        display: flex;
        padding: 0 toRem(27);
        box-sizing: border-box;
        justify-content: space-between;
        font-size: toRem(14);
        background-color: #fff;

        div {
            line-height: toRem(50);
            position: relative;
        }
        .active{
            font-weight: bold;
            font-size: toRem(16);
            color: #3B6CF5;
            &:before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                z-index: 3;
                transform: translateX(-50%);
                width: toRem(23);
                height: toRem(2);
                background-color: #3B6CF5;
            }
        }
    }

    .list {
        margin-top: toRem(63);
        background-color: #fff;
        border-radius: toRem(8);

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: toRem(13) toRem(13);
            border-bottom: toRem(1) solid #F4F7FC;

            &:last-child {
                border: 0;
            }

            .left {
                div:nth-child(1) {
                    font-size: toRem(14);
                    color: #333;
                    margin-bottom: toRem(5);
                }

                div:nth-child(2) {
                    font-size: toRem(12);
                    color: #999;
                }
            }

            .right {
                font-size: toRem(18);
                font-weight: bold;
                color: #FF4343;
            }
        }
    }
}