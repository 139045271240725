@function toRem($a) {
    @return ($a/37.5) + rem;
}

.loading{
    width:  toRem(375);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        width: toRem(100);
        height: toRem(100);
        transform: translateY(-50%);
    }
}