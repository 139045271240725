@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pay-info{
    background-color: #fff;
    overflow: hidden;
    padding:0 toRem(14);
    padding-bottom: toRem(45);
    .logo{
        margin: toRem(30) 0;
        display: flex;
        justify-content: center;
        img{
            width: toRem(267.2/2);
            height: toRem(66/2);
        }
    }
    .shop-info{
        margin-bottom: toRem(10);
        display: flex;
        justify-content: space-between;
        align-items: center;
        div:nth-child(1){
            font-size: toRem(14);
            color: #999;
        }
        div:nth-child(2){
            display: flex;
            align-items: center;
            color: #000;
            font-weight: bold;
            font-size:toRem(16);
            img{
                width: toRem(36);
                height: toRem(36);
                border-radius: toRem(4);
                margin-left: toRem(8);
            }
        }
    }
    .pay-amount{
        .title{
            font-size: toRem(14);
            color: #999;
            margin-bottom: toRem(18);
        }
        .put{
            display: flex;
            align-items: center;
            border-bottom: toRem(1) solid #EFEFEF;
            margin-bottom: toRem(12);
            span{
                font-size: toRem(20);
                font-weight: bold;
                color: #333;
                margin-right: toRem(4);
            }
            input{
                font-weight: bold;
                font-size: toRem(33);
                color: #333;
            }
            img{
                width: toRem(20);
                height: toRem(20);
            }
        }
        p{
            font-size: toRem(11);
            color: #ccc;
        }
    }
}
.pay-keyboard{
    padding:toRem(12);
    display: flex;
    justify-content: space-between;
    .keyboard{
        display: flex;
        flex-wrap: wrap;
        div{
            width: toRem(80);
            height: toRem(48);
            margin-bottom: toRem(8);
            margin-right: toRem(11);
            border-radius: toRem(4);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333;
            font-size: toRem(24);
            font-weight: bold;
            border: 0;
            background-color: #fff;
            position: relative;
           
            &:nth-child(3),&:nth-child(6),&:nth-child(9){
                margin-right: 0;
            }
            &:nth-child(10){
                height: toRem(44);
                width: toRem(171);
                margin-bottom: 0;
            }
            &:nth-child(11){
                height: toRem(44);
                margin: 0;
            }
        }
        div:before{
            position: absolute;
            left: 0;
            top: 0;
            right:0;
            bottom: 0;
            background: rgba(0,0,0,0.03);
        }
        div:active:before{
            content: '';
        }
    }
    .clear-confirm{
        div{
            position: relative;
        }
        div:nth-child(1){
            width: toRem(80);
            height: toRem(48);
            margin-bottom: toRem(8);
            border-radius: toRem(4);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            img{
                width: toRem(64.61/2);
                height: toRem(42/2);
            }
        }
        div:nth-child(2){
            width: toRem(80);
            border-radius: toRem(4);
            background-color: #3B6CF5;
            font-size: toRem(20);
            color: #fff;
            font-weight: bold;
            height: toRem(156);
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing:toRem(5);
        }
        div:before{
            position: absolute;
            left: 0;
            top: 0;
            right:0;
            bottom: 0;
            background: rgba(0,0,0,0.03);
        }
        div:active:before{
            content: '';
        }
    }
}
.banner{
    flex:1;
    background-color: #fff;
    padding:toRem(10) toRem(14) toRem(20) toRem(14);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img{
        height: toRem(113);
        width: 100%;
    }
    p{
        color: #999;
        font-size: toRem(12);
    }
}