@function toRem($a) {
    @return ($a/37.5) + rem;
}

.confirm-goods {
    font-size: toRem(14);
    color: #333;
    overflow: hidden;
    padding-bottom: toRem(90);
    .address-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: toRem(13) toRem(15);
        margin-top: toRem(10);
        margin-bottom: toRem(10);
        .left {
            display: flex;
            align-items: center;
            margin-right: toRem(33);
            flex: 1;
            p:nth-child(1) {
                font-size: toRem(16);
                margin-bottom: toRem(5);
                span:nth-child(1) {
                    font-weight: bold;
                }
            }
            p:nth-child(2) {
                font-size: toRem(12);
            }
        }
        .right img {
            width: toRem(6.31);
            height: toRem(12);
        }
    }
    .order-goods-list {
        background-color: #fff;
        margin-top: toRem(8);
        padding: toRem(15);
        .shop {
            display: flex;
            align-items: center;
            margin-bottom: toRem(13);
            img {
                width: toRem(24);
                height: toRem(24);
                border-radius: 50%;
                margin-right: toRem(8);
            }
        }
        .item {
            margin-bottom: toRem(10);
            &:last-child {
                margin: 0;
            }
            .goods {
                display: flex;
                img {
                    border-radius: toRem(5);
                    width: toRem(80);
                    height: toRem(80);
                    margin-right: toRem(12);
                }
                .title-price {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    div:nth-child(1) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    div:nth-child(2) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span:first-child {
                            color: #999;
                            font-size: toRem(12);
                        }
                    }
                }
            }
        }
    }
    .order-num {
        margin-top: toRem(8);
        background-color: #fff;
        padding: 0 toRem(15);
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: toRem(49);
            border-bottom: toRem(1) solid #f5f5f5;
            box-sizing: border-box;
            span:nth-child(1) {
                color: #999;
            }
        }
    }
    .message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        margin-top: toRem(8);
        height: toRem(45);
        padding: 0 toRem(15);
        span {
            color: #999;
        }
        .adm-input {
            flex: 1;
            input {
                font-size: toRem(14);
            }
        }
    }
    .conform-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: toRem(15);
        box-sizing: border-box;
        padding: toRem(15) toRem(18);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        .price {
            span:nth-child(2) {
                color: #ff1607;
                font-weight: bold;
            }
        }
        .submit {
            background-color: #ff1607;
            color: #fff;
            border-radius: toRem(22);
            font-weight: bold;
            line-height: toRem(44);
            text-align: center;
            width: toRem(120);
        }
    }
}
