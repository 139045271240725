@function toRem($a) {
    @return ($a/37.5) + rem;
}

.community-details{
    padding: toRem(14);
    font-size: toRem(14);
    color: #333;
    background-color: #fff;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    .hand {
        position: fixed;
        top: toRem(10);
        right: toRem(5);
        width: toRem(40);
        height: toRem(40);
        z-index: 10;
        animation: move 1s infinite alternate;
    }
    @keyframes move {
        from {
            top: toRem(10);
        }
        to {
            top: toRem(20);
        }
    }
    .name{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: toRem(15);
        .left{
            display: flex;
            align-items: center;
            img{
                width: toRem(44);
                height: toRem(44);
                border-radius: 50%;
                margin-right:toRem(8);
            }
            div{
                p:nth-child(1){
                    font-weight: bold;
                    margin-bottom: toRem(5);
                }
                p:nth-child(2){
                    font-size: toRem(13);
                    color: #999;
                }
            }
        }
        .right{
            width: toRem(70);
            height: toRem(30);
            border:toRem(1) solid #486BEB;
            border-radius: toRem(16);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #486BEB;
        }
        .right-active{
            color: #ccc;
            border-color: #EDEFF1;
        }
    }
    .title{
        font-size: toRem(16);
        font-weight: bold;
        margin-bottom: toRem(11);
    }
    .content{
        margin-bottom: toRem(11);
        textarea{
            font-size: toRem(14.5);
        }
    }
    .video{
        min-height: toRem(350);
        margin-bottom: toRem(11);
        position: relative;
        display: flex;
        align-items: center;
        background-color: black;
        video{
            width: 100%;
            max-height: toRem(480);
            object-fit: cover;
        }
        img{
            position: absolute;
            z-index: 10;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: toRem(40);
            height: toRem(40);
        }
    }
    .img{
        height: toRem(400);
        margin-bottom: toRem(11);
        .adm-swiper{
            height: 100%;
        }
        .adm-swiper-item{
            display: flex;
            align-items: center;
        }
    }
    .like-collect-share{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .share{
            width: toRem(23);
            height: toRem(23);
        }
        .like{
            display: flex;
            align-items: center;
            color: #9FA3AB;
            img{
                margin-right: toRem(15/2);
                width: toRem(37.94/2);
                height: toRem(34/2);
            }
        }
        .collect{
            width: toRem(37.94/2);
            height: toRem(34/2);
        }
    }
}