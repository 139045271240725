@function toRem($a) {
    @return ($a/37.5) + rem;
}

.addAddress {
    font-size: toRem(14);
    color: #333;
    .notice {
        height: toRem(40);
        background: #ffd673;
        padding: 0 toRem(16);
        display: flex;
        align-items: center;
        .notice_iamge {
            width: toRem(20);
            height: toRem(20);
            margin-right: toRem(12);
        }
        .notice_text {
            font-size: toRem(12);
            font-weight: 400;
            color: #fff;
        }
    }
    .item {
        display: flex;
        height: toRem(55);
        margin: 0 toRem(16);
        align-items: center;
        .item_iamge {
            width: toRem(20);
            height: toRem(20);
            margin-right: toRem(12);
        }
        .rigth{
            width: toRem(6.31);
            height: toRem(12);
        }
        .item_label {
            flex: 1;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
        }
        .item_input {
            text-align: right;
            width: toRem(230);
            input {
                font-size: toRem(14);
                text-align: right;
            }
        }
        .picker {
            width: toRem(200);
            text-align: right;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .bb {
        border-bottom: toRem(1) solid #ededed;
    }
    .btn {
        margin:0 auto;
        width: toRem(240);
        height: toRem(40);
        border-radius: toRem(20);
        line-height: toRem(40);
        text-align: center;
        color: #ffffff;
        color: #fff;
        background-color: #ff5a01;
        margin-top:toRem(200);
        margin-bottom: toRem(10);
    }
    .delete{
        margin:0 auto;
        width: toRem(240);
        height: toRem(40);
        border-radius: toRem(20);
        line-height: toRem(40);
        text-align: center;
        color: #ffffff;
        color: #ff5a01;
        border:toRem(1) solid #ff5a01;
    }
    .deleteModel{
		position: fixed;
		top: 0;
		left:0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.4);
		z-index: 10;
		.delete_main{
			position: absolute;
			top: 40%;
			left: toRem(115/2);
			width: toRem(260);
			height: toRem(150);
			background: #FFFFFF;
			border-radius: toRem(8);
			overflow: hidden;
			.delete_content{
				margin: toRem(41) 0;
				font-size: toRem(16);
				text-align: center;
			}
			.delete_buttons{
				display: flex;
				.delete_cancle{
					flex: 1;
					height: toRem(49);
					border-top: toRem(1) solid #F5F5F5;
					line-height: toRem(49);
					text-align: center;
					font-size: toRem(16);
				}
				.delete_confirm{
					flex: 1;
					height: toRem(50);
					line-height: toRem(50);
					text-align: center;
					font-size: toRem(16);
					color: #ffffff;
                    background-color:#ff5a01;
				}
			}
		}
	}
}
