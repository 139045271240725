@function toRem($a) {
    @return ($a/37.5) + rem;
}

.activated{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #fff;
    padding:0 toRem(14);
    img{
        margin-bottom: toRem(20);
    }
    p{
        font-size: toRem(13);
        color: #999;
        line-height: toRem(22);
    }
}