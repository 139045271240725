@function toRem($a) {
    @return ($a/37.5) + rem;
}

.publishing-center {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y: scroll;
    .header {
        height: toRem(190);
        background-size: 100% 100%;
        overflow: hidden;
        padding:0 toRem(14);
        .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: toRem(28);
            div:nth-child(1) {
                color: #fff;
                font-weight: bold;
                font-size: toRem(20);
                display: flex;
                align-items: center;
                img {
                    width: toRem(74);
                    height: toRem(74);
                    border-radius: 50%;
                    margin-right: toRem(14);
                }
            }
            div:nth-child(2){
                width: toRem(75);
                height: toRem(32);
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #486BEB;
                border-radius: toRem(16);
                box-sizing: border-box;
            }
            .follow{
                border:toRem(1) solid #fff;
                background-color: rgba(255, 255, 255, 0.2)!important;                
            }
        }
        .total-num {
            display: flex;
            margin-top: toRem(20);
            div {
                padding: toRem(5) toRem(15);
                border-radius: toRem(27/2);
                background-color: rgba(255, 255, 255, 0.3);
                color: #fff;
                margin-right: toRem(10);
            }
        }
    }
    .tabs {
        background-color: #fff;
        border-radius: toRem(12) toRem(12) 0 0;
        margin-top: toRem(-20);
        display: flex;
        justify-content: center;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
        div {
            font-size: toRem(16);
            height: toRem(46);
            color: #999;
            display: flex;
            align-items: center;
            margin-right: toRem(118/2);
            &:last-child {
                margin: 0;
            }
        }
        .active {
            color: #333;
            font-weight: bold;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: toRem(15/2);
                width: toRem(68/2);
                height: toRem(3);
                border-radius: toRem(2);
                background-color: #486beb;
                transform: translateX(-50%);
            }
        }
    }
    .list {
        padding: 0 toRem(14);
        margin-top: toRem(14);
        display: flex;
        .left {
            margin-right: toRem(7);
            .item {
                background-color: #fff;
                border-radius: toRem(8);
                margin-bottom: toRem(7);
                padding-bottom: toRem(10);
                width: toRem(170);
                .cover {
                    width: toRem(170);
                    height: toRem(190);
                    object-fit: cover;
                    border-radius: toRem(8) toRem(8) 0 0;
                }
                .title {
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-top: toRem(5);
                    padding: 0 toRem(8);
                }
                .date {
                    margin-top: toRem(10);
                    display: flex;
                    justify-content: space-between;
                    padding: 0 toRem(8);
                    color: #999;
                    font-size: toRem(12);
                    div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(26.78/2);
                            height: toRem(24/2);
                            margin-right: toRem(9/2);
                        }
                    }
                }
            }
        }
        .right {
            .item {
                background-color: #fff;
                border-radius: toRem(8);
                margin-bottom: toRem(7);
                padding-bottom: toRem(10);
                width: toRem(170);
                .cover {
                    width: toRem(170);
                    height: toRem(190);
                    object-fit: cover;
                    border-radius: toRem(8) toRem(8) 0 0;
                }
                .title {
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-top: toRem(5);
                    padding: 0 toRem(8);
                }
                .date {
                    margin-top: toRem(10);
                    display: flex;
                    justify-content: space-between;
                    padding: 0 toRem(8);
                    color: #999;
                    font-size: toRem(12);
                    div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        img {
                            width: toRem(26.78/2);
                            height: toRem(24/2);
                            margin-right: toRem(9/2);
                        }
                    }
                }
            }
        }
    }
    .release-no img {
        width: toRem(200);
        height: toRem(336.47/2);
        margin-top: toRem(54);
    }
    .collect-no img {
        width: toRem(200);
        height: toRem(200);
        margin-top: toRem(20);
    }
    .like-no img {
        width: toRem(200);
        height: toRem(200);
        margin-top: toRem(20);
    }
    .no {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            font-size: toRem(13);
            color: #999;
            margin-top: toRem(8);
        }
        div {
            width: toRem(90);
            height: toRem(30);
            background: #486beb;
            border-radius: toRem(21);
            color: #fff;
            text-align: center;
            line-height: toRem(30);
            margin-top: toRem(15);
        }
    }
    .release-btn {
        width: toRem(293/2);
        height: toRem(44);
        background-color: #486beb;
        border-radius: toRem(22);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: toRem(23);
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);
        img {
            width: toRem(20);
            height: toRem(37.77/2);
            margin-right: toRem(6);
        }
    }
}
.release-type {
    height: 100%;
    background-size: 100% 100%;
    overflow: hidden;
    padding-left: toRem(29);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    div:nth-child(1) {
        font-size: toRem(44);
        margin-top: toRem(273/2);
    }
    div:nth-child(2) {
        margin-top: toRem(20);
        font-size: toRem(20);
    }
    div:nth-child(3) {
        margin-top: toRem(50);
        display: flex;
        img {
            width: toRem(303/2);
            height: toRem(420/2);
            &:nth-child(1) {
                margin-right: toRem(14);
            }
        }
    }
    div:nth-child(4) {
        margin-top: toRem(28);
        text-align: center;
        padding-right: toRem(29);
        margin-bottom: toRem(75/2);
        img {
            width: toRem(50);
            height: toRem(50);
        }
    }
}
.remove-modal {
    background-color: #f7f9fa;
    div {
        background-color: #fff;
        text-align: center;
        font-size: toRem(16);
        &:nth-child(1) {
            color: #999;
            line-height: toRem(153/2);
            border-bottom: toRem(1) solid #e2e2e2;
        }
        &:nth-child(2) {
            line-height: toRem(50);
            color: #fd4545;
            margin-bottom: toRem(12);
        }
        &:nth-child(3) {
            line-height: toRem(50);
        }
    }
}
