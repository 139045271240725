@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content{
    padding:0 toRem(13);
    overflow: hidden;
    font-size: toRem(14);
    color: #333;
    .form_info{
        border-radius: toRem(8);
        background-color: #fff;
        margin-top: toRem(13);
        padding:toRem(15) toRem(13);
        .title{
            font-size: toRem(16);
            margin-bottom: toRem(15);
            span{
                font-size: toRem(12);
                color: #999;
            }
        }

    }
    .btn{
        margin-top:toRem(58);
        line-height: toRem(41);
        text-align: center;
        color: #fff;
        font-size: toRem(14);
        background-color: #3B6CF5;
        border-radius: toRem(21);
    }
}