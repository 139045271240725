@function toRem($a) {
    @return ($a/37.5) + rem;
}

.back {
    height: 100%;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    div {
        width: toRem(186);
        height: toRem(44);
        border-radius: toRem(25);
        border: toRem(2) solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: toRem(18);
        font-weight: bold;
        margin-bottom: toRem(50);
    }
}
