@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content {
    overflow: hidden;
    .hot {
        height: toRem(221);
        background-size: 100% 100%;
        overflow: hidden;
        padding: 0 toRem(14);
        p {
            font-size: toRem(18);
            font-weight: bold;
            margin: toRem(15) 0;
        }
        div {
            display: flex;
            justify-content: space-between;
            img {
                width: toRem(170);
                height: toRem(130);
            }
        }
    }
    .restaurant {
        padding: 0 toRem(14);
        margin-bottom: toRem(24);
        p {
            font-size: toRem(18);
            font-weight: bold;
            margin-bottom: toRem(15);
        }
        .list {
            background: linear-gradient(-50deg, #fff4de 0%, #ffe6af 100%);
            height: toRem(160);
            border-radius: toRem(10);
            overflow-x: scroll;
            display: flex;
            align-items: center;
            padding-top: toRem(30);
            box-sizing: border-box;
            padding-left: toRem(11);
            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
            .item {
                flex: none;
                background-size: 100% 100%;
                width: toRem(90);
                height: toRem(90);
                margin-right: toRem(11);
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: toRem(60);
                    height: toRem(60);
                    margin-top: toRem(-30);
                    margin-bottom: toRem(6);
                }
                .name {
                    color: #733201;
                    margin-bottom: toRem(6);
                }
                .zhekou {
                    border-radius: toRem(8) toRem(8) 0px 0px;
                    background: #fff4d3;
                    width: toRem(70);
                    height: toRem(17);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FF5000;
                }
            }
        }
    }
    .more{
        padding: 0 toRem(14);
        overflow:hidden;
        p {
            font-size: toRem(18);
            font-weight: bold;
            margin-bottom: toRem(15);
        }
        .service{
            display: flex;
            justify-content: space-between;
            .left,.right{
                background: #fff;
                border-radius: toRem(10);
                padding:toRem(14);
                .title{
                    font-size: toRem(16);
                    margin-bottom: toRem(10);
                }
                .jd,.youku{
                    height: toRem(122);
                    width: 100%;
                }
                .mores{
                    display: flex;
                    justify-content: space-between;
                    margin-top: toRem(14);
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    img{
                        width: toRem(43);
                        height: toRem(43);
                        margin-bottom: toRem(6);
                    }
                    p{
                        font-size: toRem(13);
                        margin:0;
                    }
                }
            }
        }
    }
}
