@function toRem($a) {
    @return ($a/37.5) + rem;
}

.settlement-info {
    font-size: toRem(14);
    color: #333;
    padding: toRem(12);
    .speed {
        background-color: #fff;
        padding: toRem(25) 0 toRem(16) 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: toRem(12);
        margin-bottom: toRem(12);
        div:nth-child(1) {
            display: flex;
            align-items: center;
            font-size: toRem(11);
            color: #ccc;
            margin-bottom: toRem(16);
            span:nth-child(1),
            span:nth-child(3),
            span:nth-child(5) {
                width: toRem(18);
                height: toRem(18);
                border-radius: 50%;
                display: flex;
                box-sizing: border-box;
                align-items: center;
                background-color: #ff5a01;
                color: #fff;
                justify-content: center;
            }
            span:nth-child(2),
            span:nth-child(4) {
                width: toRem(68);
                height: toRem(1);
                background-color: #ff5a01;
                margin: 0 toRem(15);
            }
        }
        div:nth-child(2) {
            display: flex;
            color: #ff5a01;
            width: 100%;
            font-weight: bold;
            span {
                flex: 1;
                text-align: center;
            }
        }
        .audit {
            line-height: toRem(36);
            text-align: center;
            border-radius: toRem(4);
            margin-top: toRem(13);
            background-color: #fff7f2;
            color: #ff5a01;
            width: toRem(315);
        }
        .reject {
            padding: toRem(9) 0;
            text-align: center;
            border-radius: toRem(4);
            margin-top: toRem(13);
            background-color: #fff3f3;
            color: #ff5353;
            width: toRem(315);
        }
    }
    .settlement-name {
        margin-bottom: toRem(10);
        font-size: toRem(15);
        font-weight: bold;
        padding-left: toRem(12);
    }
    .personal-info {
        margin-bottom: toRem(10);
        background-color: #fff;
        border-radius: toRem(12);
        padding: toRem(17) toRem(12) toRem(17) toRem(12);
        div {
            display: flex;
            justify-content: space-between;
            margin-bottom: toRem(17);
            &:last-child {
                margin: 0;
            }
            span:last-child {
                font-weight: bold;
                margin-left:toRem(10);
                flex:1;
            }
        }
    }
    .bank-info {
        border-radius: toRem(12);
        padding: toRem(17) 0 toRem(20) 0;
        background-color: #fff;
        margin-bottom: toRem(20);
        .info-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: toRem(17);
            padding: 0 toRem(12);
            span:last-child {
                font-weight: bold;
                margin-left:toRem(10);
                flex:1;
            }
        }
        .identity {
            margin-bottom: toRem(17);
            padding: 0 toRem(12);
            .title {
                margin-bottom: toRem(13);
            }
            .card {
                display: flex;
                justify-content: space-between;
                div {
                    width: toRem(159);
                    height: toRem(159);
                    background-color: #f7f9fa;
                    border-radius: toRem(8);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: toRem(130);
                        height: toRem(84.22);
                        object-fit: cover;
                        border-radius: toRem(6);
                    }
                }
            }
        }
        .contract {
            margin-bottom: toRem(17);
            .title {
                color: #666;
                margin-bottom: toRem(13);
                padding-left: toRem(12);
            }
            .contract-img {
                display: flex;
                overflow-x: scroll;
                align-items: center;
                &::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                }
                img {
                    width: toRem(111);
                    height: toRem(111);
                    border-radius: toRem(8);
                    margin-left: toRem(9);
                    flex: none;
                    &:first-child {
                        margin-left: toRem(12);
                    }
                    &:last-child {
                        margin-right: toRem(12);
                    }
                }
            }
        }
        .business-license {
            padding: 0 toRem(12);
            .title {
                color: #666;
                margin-bottom: toRem(13);
            }
            .img {
                height: toRem(180);
                width: 100%;
                background-color: #f7f9fa;
                border-radius: toRem(6);
                display: flex;
                justify-content: center;
                img {
                    height: 100%;
                    width: toRem(258);
                    object-fit: cover;
                }
            }
        }
    }
    .shops {
        margin-bottom: toRem(10);
        font-weight: bold;
        font-size: toRem(15);
        padding-left: toRem(12);
    }
    .shop-infos {
        margin-bottom: toRem(10);
        background-color: #fff;
        border-radius: toRem(12);
        padding: toRem(17) toRem(12) toRem(17) toRem(12);
        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: toRem(17);
            &:last-child {
                margin: 0;
            }
            img {
                width: toRem(40);
                height: toRem(40);
                border-radius: 50%;
            }
            span:nth-child(2) {
                font-weight: bold;
                margin-left:toRem(10);
                flex:1;
            }
        }
    }
    .category {
        background-color: #fff;
        border-radius: toRem(12);
        padding: toRem(17) 0 toRem(20) 0;
        margin-bottom: toRem(60);
        .category-name {
            display: flex;
            justify-content: space-between;
            padding: 0 toRem(12);
            span:last-child {
                font-weight: bold;
                margin-left:toRem(10);
                flex:1;
            }
        }
        .category-img {
            margin-top: toRem(17);
            div:nth-child(1) {
                margin-bottom: toRem(13);
                padding-left: toRem(12);
            }
            div:nth-child(2) {
                display: flex;
                overflow-x: scroll;
                align-items: center;
                &::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                }
                img {
                    width: toRem(111);
                    height: toRem(111);
                    border-radius: toRem(8);
                    margin-left: toRem(9);
                    flex: none;
                    &:first-child {
                        margin-left: toRem(12);
                    }
                    &:last-child {
                        margin-right: toRem(12);
                    }
                }
            }
        }
    }
    .reset {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 10;
        padding: toRem(10) toRem(12);
        display: flex;
        box-sizing: border-box;
        div {
            line-height: toRem(40);
            text-align: center;
            flex: 1;
            background-color: #ff5a01;
            border-radius: toRem(25);
            color: #fff;
        }
    }
}
