@function toRem($a) {
    @return ($a/37.5) + rem;
}

.enterprise-order-details {
    font-size: toRem(14);
    color: #333;
    .pay-state {
        background-color: #ff0f0b;
        height: toRem(135);
        width: 100%;
        padding-left: toRem(30);
        box-sizing: border-box;
        color: #fff;
        border-bottom-left-radius: 60% 15%;
        border-bottom-right-radius: 60% 15%;
        overflow: hidden;
        padding-top: toRem(25);
        display: flex;
        img {
            width: toRem(30);
            height: toRem(30);
            margin-right: toRem(10);
        }
        p {
            font-size: toRem(22);
            font-weight: bold;
        }
    }
    .order-con {
        padding: 0 toRem(14);
        margin-top: toRem(-51);
        .address {
            background-color: #fff;
            border-radius: toRem(8);
            padding: toRem(14);
            margin-bottom: toRem(10);
            display: flex;
            align-items: center;
            img {
                width: toRem(24);
                height: toRem(24);
                margin-right: toRem(15);
            }
            div {
                p:nth-child(1) {
                    font-weight: bold;
                    font-size: toRem(16);
                    margin-bottom: toRem(5);
                }
            }
        }
        .goods-info {
            background-color: #fff;
            border-radius: toRem(8);
            margin-bottom: toRem(10);
            padding: toRem(14);
            .store {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: toRem(10);
                div {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: toRem(16);
                    img {
                        width: toRem(20);
                        height: toRem(20);
                        margin-right: toRem(5);
                    }
                }
                span {
                    font-size: toRem(12);
                    color: #999;
                }
            }
            .goods {
                display: flex;
                margin-bottom: toRem(10);
                &:last-child {
                    margin: 0;
                }
                img {
                    width: toRem(80);
                    height: toRem(80);
                    border-radius: toRem(5);
                    margin-right: toRem(14);
                }
                .goods-con {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .title {
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        div:nth-child(1) {
                            font-family: Source Han Sans CN;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-right: toRem(2);
                        }
                    }
                    .spec {
                        display: flex;
                        justify-content: space-between;
                        color: #999;
                        font-size: toRem(12);
                        margin-top: toRem(8);
                    }
                    .refund-state {
                        width: toRem(70);
                        border: toRem(1) solid #e6e6e6;
                        border-radius: toRem(14);
                        box-sizing: border-box;
                        font-size: toRem(12);
                        text-align: center;
                        line-height: toRem(28);
                        margin-left:auto;
                    }
                    .redText {
                        border-radius: toRem(14);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: toRem(12);
                        font-family: Source Han Sans CN;
                        color: #FF7373;
                    }
                }
            }
        }
        .order-info {
            background-color: #fff;
            border-radius: toRem(8);
            padding: toRem(14);
            .info-item {
                display: flex;
                justify-content: space-between;
                margin-bottom: toRem(14);
                &:last-child {
                    margin: 0;
                }
                &:nth-child(1) div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    span {
                        width: toRem(40);
                        line-height: toRem(21);
                        border-radius: toRem(4);
                        background-color: #fff0de;
                        color: #ff9100;
                        font-size: toRem(12);
                        text-align: center;
                        margin-right: toRem(10);
                    }
                }
                div:nth-child(2) {
                    font-weight: bold;
                }
            }
        }
        .message {
            display: flex;
            background-color: #fff;
            border-radius: toRem(8);
            padding: toRem(14);
            margin-top: toRem(10);
            justify-content: space-between;
            div:nth-child(2) {
                flex: 1;
                margin-left: toRem(50);
                text-align: right;
            }
        }
    }
    .btn-state {
        display: flex;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-right: toRem(14);
        div {
            width: toRem(110);
            height: toRem(44);
            border-radius: toRem(22);
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: toRem(16);
            margin: toRem(14) 0;
            margin-left: toRem(10);
            &:nth-child(1) {
                border: toRem(1) solid #e6e6e6;
                background-color: #fff;
            }
            &:nth-child(2) {
                color: #fff;
                background-color: #ff0f0b;
            }
        }
    }
}
