@function toRem($a) {
    @return ($a/37.5) + rem;
}

.release {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y: auto;
    background: #fff;
    padding: toRem(14);
    padding-bottom: toRem(40);
    .word {
        background: #f7f9fa;
        border-radius: toRem(8);
        padding: 0 toRem(12) toRem(15) toRem(12);
        margin-bottom: toRem(10);
        .title {
            height: toRem(52);
            display: flex;
            align-items: center;
            border-bottom: toRem(1) solid #e2e2e2;
            margin-bottom: toRem(15);
            input {
                font-weight: bold;
                font-size: toRem(16);
            }
        }
        .content {
            textarea {
                font-size: toRem(14);
            }
        }
    }
    .images .title {
        font-size: toRem(16);
        font-weight: bold;
        margin-bottom: toRem(10);
        &:after {
            content: "（至少添加一张图片）";
            color: #999;
            font-size: toRem(13);
            font-weight: 500;
        }
    }
    .video .title {
        font-size: toRem(16);
        font-weight: bold;
        margin-bottom: toRem(10);
        &:after {
            content: "（至少添加一个视频）";
            color: #999;
            font-size: toRem(13);
            font-weight: 500;
        }
    }
    .images,
    .video {
        background: #f7f9fa;
        border-radius: toRem(8);
        padding: toRem(15) 0 toRem(5) toRem(12);
        margin-bottom: toRem(10);

        .tips {
            font-size: toRem(13);
            color: #ccc;
            margin-bottom: toRem(12);
        }
        .add {
            display: flex;
            flex-wrap: wrap;
            .img-list {
                display: flex;
                flex-wrap: wrap;
                .img-item {
                    width: toRem(100);
                    height: toRem(100);
                    border-radius: toRem(6);
                    margin-bottom: toRem(10);
                    position: relative;
                    margin-right: toRem(10);
                    .adm-image {
                        height: 100%;
                        width: 100%;
                        img {
                            border-radius: toRem(6);
                        }
                    }
                    .remove {
                        position: absolute;
                        width: toRem(16);
                        height: toRem(16);
                        top: toRem(-8);
                        right: toRem(-8);
                    }
                    .first {
                        position: absolute;
                        right: toRem(6);
                        bottom: toRem(8);
                        background-color: rgba(0, 0, 0, 0.4);
                        color: #fff;
                        width: toRem(44);
                        height: toRem(22);
                        font-size: toRem(12);
                        text-align: center;
                        line-height: toRem(22);
                        border-radius: toRem(12);
                    }
                }
            }

            .add-img {
                background: #fff;
                border: toRem(0.5) solid #cccccc;
                border-radius: toRem(6);
                width: toRem(100);
                height: toRem(100);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                box-sizing: border-box;
                margin-bottom: toRem(10);
                img {
                    width: toRem(40.95/2);
                    height: toRem(39.45/2);
                    margin-bottom: toRem(6);
                }
                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }
        }
       
    }
    .classify {
        background: #f7f9fa;
        border-radius: toRem(8);
        padding: toRem(15) toRem(12) toRem(5) toRem(12);
        .title {
            font-size: toRem(16);
            font-weight: bold;
            margin-bottom: toRem(15);
        }
        .cate-list {
            display: flex;
            flex-wrap: wrap;
            div {
                padding: toRem(7) toRem(30);
                border-radius: toRem(6);
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                margin-right: toRem(12);
                margin-bottom: toRem(12);
            }
            .active {
                background: rgba(72, 107, 235, 0.08);
                border: toRem(1) solid #486beb;
            }
        }
    }
    .confirm {
        position: fixed;
        bottom: toRem(24);
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 toRem(14);
        display: flex;
        justify-content: center;
        div {
            width: toRem(250);
            height: toRem(44);
            border-radius: toRem(22);
            background: #486beb;
            color: #fff;
            line-height: toRem(44);
            text-align: center;
        }
    }
}
