@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content{
    padding:0 toRem(14);
    color: #333;
    overflow: hidden;
    .header{
        position: fixed;
        z-index: 2;
        width: 100%;
        background-color: #fff;
        top:0;
        left:0;
        box-sizing: border-box;
        padding:toRem(8) toRem(12) 0 toRem(12);
        .search{
            display: flex;
            align-items: center;
            padding:0 toRem(12);
            height: toRem(34);
            background-color: #E8EBEF;
            padding-left: toRem(14);
            border-radius: toRem(17);
            input{
                border:0;
                background: none;
                font-size: toRem(12.5);
                margin-left: toRem(3);
                flex:1;
                outline: none;
            }
        }
        .status{
            display: flex;
            padding:0 toRem(15);
            display: flex;
            justify-content: space-between;
            div{
                line-height: toRem(47);
                font-size: toRem(14);
            }
            .active{
                font-size: toRem(16);
                color: #3B6CF5;
                font-weight: bold;
            }
        }
    }
    .list{
        margin-top:toRem(103);
        .item{
            background-color: #fff;
            border-radius: toRem(8);
            padding:toRem(15) toRem(13);
            margin-bottom: toRem(13);
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: toRem(16);
                margin-bottom: toRem(15);
                span:nth-child(1){
                    font-weight: bold;
                    flex:1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                span:nth-child(2){
                    color: #FF5A01;
                }
            }
            .order_con{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left{
                    font-size: toRem(14);
                    div:nth-child(1){
                        margin-bottom: toRem(5);
                    }
                }
                .right{
                    font-size: toRem(18);
                    font-weight: bold;
                }
            }
        }
    }
}