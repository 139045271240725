@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content{
    padding:0 toRem(14);
    overflow: hidden;
    color: #333;
    .card{
        background-color: #fff;
        border-radius: toRem(8);
        margin:toRem(13) 0;
        height: toRem(50);
        padding:0 toRem(13);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: toRem(14);
        div:nth-child(2){
            color: #999;
        }
    }
    .withdrawal_money{
        background-color: #fff;
        border-radius: toRem(8);
        padding:toRem(13) toRem(15);
        .title{
            font-size: toRem(14);
            margin-bottom: toRem(20);

        }
        .put{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: toRem(20);
            div:nth-child(1){
                display:flex;
                align-items: center;
                span{
                    font-size: toRem(23);
                    font-weight: bold;
                    margin-right: toRem(8);
                }
                input{
                    border:0;
                    width: toRem(100);
                    outline: none;
                    font-size: toRem(23);
                    &::placeholder{
                        color: #ccc;
                    }
                }
            }
            div:nth-child(2){
                width: toRem(150);
                font-size: toRem(14);
                text-align: right;
                color: #ccc;
            }
        }
        .can_withdrawal{
            font-size: toRem(12);
            span:nth-child(2){
                color: #3B6CF5;
                margin-left: toRem(8);
            }
        }
    }
    .btn{
        margin-top:toRem(58);
        line-height: toRem(41);
        text-align: center;
        color: #fff;
        font-size: toRem(14);
        background-color: #3B6CF5;
        border-radius: toRem(21);
    }
    .time{
        text-align: center;
        margin-top:toRem(20);
    }
}