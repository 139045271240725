@function toRem($a) {
    @return ($a/37.5)+rem;
}

.content {
    color: #333;
    height: 100%;
    overflow-y: scroll;
    .type {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: toRem(50);
        z-index: 2;
        display: flex;
        padding: 0 toRem(27);
        box-sizing: border-box;
        justify-content: space-between;
        font-size: toRem(14);
        background-color: #fff;
        margin-bottom: toRem(10);
        div {
            line-height: toRem(50);
            position: relative;
        }
        .active{
            font-weight: bold;
            font-size: toRem(16);
            color: #3B6CF5;
            &:before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                z-index: 3;
                transform: translateX(-50%);
                width: toRem(23);
                height: toRem(2);
                background-color: #3B6CF5;
            }
        }
    }

    .list {
        padding: 0 toRem(14);
        border-radius: toRem(8);
        .item {
        background-color: #fff;
            padding: toRem(13) toRem(13);
            border-bottom: toRem(1) solid #F4F7FC;
            color: #333;
            &:last-child {
                border: 0;
            }
            div:nth-child(1){
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: toRem(5);
                span:nth-child(1){
                    font-size: toRem(14);
                }
                span:nth-child(2){
                    font-size: toRem(18);
                    font-weight: bold;
                }
            }
            div:nth-child(2){
                display: flex;
                align-items: center;
                justify-content: space-between;
                span:nth-child(1){
                    font-size: toRem(12);
                    color: #999;
                }
                span:nth-child(2){
                    font-size: toRem(14);
                }
            }
            .reject{
                margin-top: toRem(10);
                color: #FF2E2E;
                font-size: toRem(5);
            }
        }
    }
}