@function toRem($a) {
    @return ($a/37.5) + rem;
}

.myAddress {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    background-color: #fff;
    overflow-y:scroll;
    padding-bottom: toRem(100);
    .content {
        width: toRem(686/2);
        height: toRem(186/2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: toRem(16);
        border-bottom: toRem(1) solid #f5f5f5;
    }
    .selectAddress {
        width: toRem(20);
        height: toRem(20);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .selectAddress_icon {
            width: toRem(11);
            height: toRem(8);
        }
        .uncheckedIcon {
            position: absolute;
            top: 0;
            left: 0;
            width: toRem(20);
            height: toRem(20);
            z-index: 10;
            background-color: #fff;
        }
    }
    .editAddress {
        width: toRem(16);
        height: toRem(16);
    }
    .detailedAddress {
        width: toRem(280);
        color: #999999;
        overflow: hidden;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .addressTop {
        display: flex;
        font-size: toRem(16);
        color: #333333;
        margin-bottom: toRem(9);
        align-items: center;
    }
    .phone {
        margin: 0 toRem(12) 0 toRem(13);
    }
    .default {
        width: toRem(28);
        height: toRem(15);
        background-color: #ff2626;
        border-radius: toRem(8);
        font-size: toRem(9);
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer {
        height: toRem(40);
        border-radius: toRem(20);
        width: toRem(240);
        color: #ffffff;
        background-color:#ff5a01;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left:50%;
        transform: translateX(-50%);
        bottom: toRem(30);
    }
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty_image {
            margin-top: calc(40% - toRem(43));
            width: toRem(200);
            height: toRem(150);
        }
        .footer2 {
            width: toRem(140);
            height: toRem(44);
            border: toRem(1) solid transparent;
            border-radius: toRem(22);
            font-size: toRem(16);
            line-height: toRem(44);
            text-align: center;
            position: fixed;
            left: toRem(234/2);
            bottom: toRem(392/2);
        }
        .noStyle {
            margin-top: toRem(40);
            text-align: center;
            color: #666666;
        }
    }
}
