@function toRem($a) {
    @return ($a/37.5) + rem;
}

.enterprise-shop {
    font-size: toRem(14);
    color: #333;
    overflow-y: scroll;
    height: 100%;
    background-color: #fff;
    .hand {
        position: fixed;
        top: toRem(10);
        right: toRem(5);
        width: toRem(40);
        height: toRem(40);
        z-index: 10;
        animation: move 1s infinite alternate;
    }
    .info {
        background: linear-gradient(0deg, #f9f9f9 0%, #ffd3d2 100%);
        display: flex;
        align-items: center;
        padding:toRem(25) toRem(24) toRem(40) toRem(24);
        box-sizing: border-box;
        .logo {
            width: toRem(50);
            height: toRem(50);
            border-radius: 50%;
            margin-right: toRem(8);
        }
        .name {
            flex:1;
            div {
                display: flex;
                align-items: center;
                font-size: toRem(16);
                font-weight: bold;
                margin-bottom: toRem(5);
           
                img{
                    width: toRem(15/2);
                    height: toRem(23/2);
                    margin-left: toRem(3.5);
                }
            }
            .authentication{
                width: toRem(116/2);
                height: toRem(35/2);
            }
        }
        .share{
            width: toRem(136/2);
            line-height: toRem(30);
            background-color: #FF1607;
            border-radius: toRem(15);
            color: #fff;
            font-size: toRem(13);
            text-align: center;
        }
    }
    .goods{
        background-color: #fff;
        border-radius: toRem(10) toRem(10) 0 0;
        position: relative;
        top:toRem(-15);
        .no{
            font-size: toRem(12);
            color: #999;
            text-align: center;
            margin-top: toRem(15);
        }
        .type{
            display: flex;
            align-items: center;
            height: toRem(78/2);
            color:#666;
            div:nth-child(1){
                margin:0 toRem(133/2) 0 toRem(67/2);
            }
            .active{
                color: #FF1607;
            }
        }
        .list{
            display: flex;
            flex-wrap: wrap;
            padding:0 toRem(14);
            .item{
                width: toRem(334/2);
                margin-right: toRem(13);
                padding-bottom: toRem(10);
                &:nth-child(2n+0){
                    margin-right:0;
                }
                img{
                    width: 100%;
                    height: toRem(334/2);
                    border-radius: toRem(7);
                }
                .title{
                    margin-top:toRem(7);
                    font-size: toRem(15);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .price{
                    margin-top:toRem(5);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span:nth-child(1){
                        color: #FF1607;
                        font-size: toRem(18);
                        font-weight: bold;
                        &:before{
                            content: "￥";
                            font-size: toRem(11);
                        }
                    }
                    span:nth-child(2){
                        font-size: toRem(12);
                        color: #ccc;
                    }
                }
            }
        }
    }
}
