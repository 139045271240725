@function toRem($a) {
    @return ($a/37.5) + rem;
}

.enterprise-infos {
    font-size: toRem(14);
    color: #333;
    background: linear-gradient(0deg, #f7f9fa 0%, #ffd3d2 100%);
    padding: 0 toRem(14);
    overflow:hidden;
    .hand {
        position: fixed;
        top: toRem(10);
        right: toRem(5);
        width: toRem(40);
        height: toRem(40);
        z-index: 10;
        animation: move 1s infinite alternate;
    }
    .top {
        margin-top: toRem(10);
        border-radius: toRem(10);
        background-color: #fff;
        padding: toRem(14);
        .info {
            display: flex;
            align-items: center;
        }
        .introduction{
            font-size: toRem(13);
            margin-top: toRem(13);
        }
        .logo {
            width: toRem(50);
            height: toRem(50);
            border-radius: 50%;
            margin-right: toRem(4);
        }
        .name {
            font-weight: bold;
            font-size: toRem(16);
            flex: 1;
            p {
                margin-bottom: toRem(5);
            }
            img {
                width: toRem(116/2);
                height: toRem(35/2);
            }
        } 
        .share {
            width: toRem(136/2);
            background: linear-gradient(90deg, #ff1607 0%, #ff1607 100%);
            border-radius: toRem(15);
            color: #fff;
            line-height: toRem(30);
            font-size: toRem(13);
            text-align: center;
        }
    }
    .center{
        border-radius: toRem(10);
        background-color: #fff;
        padding:toRem(14);
        margin-top: toRem(10);
        .address,.phone{
            display: flex;
            align-items: center;
            padding-bottom: toRem(10);
            border-bottom: toRem(1) solid #eee;
            margin-bottom: toRem(10);
            span{
                margin-right:toRem(14);
            }
            div{
                flex:1;
                color: #999;
            }
        }
        .business_license{
            margin-bottom: toRem(10);
            .title{
                display: flex;
                align-items: center;
                margin-bottom: toRem(15);
                span{
                    margin-right:toRem(14);
                     
                }
                div{
                    display: flex;
                    align-items: center;
                    color: #999;
                    flex:1;
                    img{
                        width: toRem(13);
                        height: toRem(31/2);
                        margin-right: toRem(5);
                    }
                }
            }
            .img{
                margin-left: toRem(42);
                width: toRem(120);
                height: toRem(120);
                border-radius: toRem(10);
                background-color: #F6F6F6;
                padding:toRem(17) 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .category{
            .title{
                display: flex;
                align-items: center;
                div{
                    color: #999;
                    margin-left:toRem(14);
                }
            }
        }
    }
}
