@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content-platform {
    color: #333;
    font-size: toRem(14);
    .box{
        padding:0 toRem(14);
    }
}
.platform {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    height: toRem(70);
    border-radius: toRem(5);
    align-items: center;
    .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        div:nth-child(1) {
            font-size: toRem(15);
            font-weight: bold;
        }
        div:nth-child(2) {
            font-size: toRem(10);
            color: #666;
            height: toRem(17);
            display: flex;
            align-items: center;
            justify-content: center;
            width: toRem(56);
        }
        .active {
            color: #fff !important;
            border-radius: toRem(14);
            background: linear-gradient(90deg, #fc7373, #e32e2e 100%);
        }
    }
}
.goods_type {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 3;
    height: toRem(50);
    margin-bottom: toRem(10);
    display: flex;
    align-items: center;
    background-color: #f4f7fc;
    .adm-tabs-header {
        border: 0;
    }
    .adm-tabs-tab-active {
        font-weight: bold;
    }
    .adm-tabs-header-mask-left {
        background: linear-gradient(to right, #f4f7fc, rgba(255, 255, 255, 0)) !important;
    }
    .adm-tabs-header-mask-right {
        background: linear-gradient(to left, #f4f7fc, rgba(255, 255, 255, 0)) !important;
    }
}

.search {
    background-color: #fff;
    padding:toRem(10) toRem(14);
    margin-bottom: toRem(15);
    input {
        font-size: toRem(13);
    }
    .adm-search-bar-active{
        border:0;
    }
    .adm-search-bar-input-box{
        border-radius: toRem(40);
    }
}
.goods-list {
    .item {
        background-color: #fff;
        border-radius: toRem(5);
        padding: toRem(10);
        margin-bottom: toRem(15);
        display: flex;
        .adm-image {
            height: toRem(115);
            width: toRem(115);
        }
        .goods-info {
            flex: 1;
            margin-left: toRem(10);
            padding:toRem(2) 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                img {
                    width: toRem(15);
                    height: toRem(15);
                    margin-bottom: toRem(-2);
                    margin-right: toRem(7);
                }
            }
            .coupon {
                width: toRem(60);
                background-size: 100% 100%;
                line-height: toRem(20);
                text-align: center;
                height: toRem(20);
                font-size: toRem(11);
                color: #fff;
            }
            .price {
                span:nth-child(1) {
                    font-size: toRem(9);
                    color: #ec3939;
                }
                span:nth-child(2) {
                    font-size: toRem(15);
                    color: #ec3939;
                }
                span:nth-child(3) {
                    font-size: toRem(9);
                    color: #bbb;
                    text-decoration: line-through;
                }
            }
            .storeName-sales {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: toRem(9);
                color: #999;
                .storeName {
                    display: flex;
                    align-items: center;
                    span {
                        width: toRem(110);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .adm-image {
                    width: toRem(15);
                    height: toRem(15);
                }
            }
        }
    }
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: toRem(12);
    color: #999;
    padding: toRem(10) 0;
    span {
        margin-left: toRem(5);
    }
}
