@function toRem($a) {
    @return ($a/37.5)+rem;
}

.content{
    font-size: toRem(14);
    color: #333;
    overflow:hidden;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: toRem(74);
    .orderContent{ 
        width: 100%;
		height:  toRem(131);
		display: flex;
		align-items: center;
		background-color: #fff;
		position: relative;
        .goodsIcon{
            width: toRem(100);
            height: toRem(100);
            margin: 0 toRem(16);
        }
        .goodsDetails{
            flex:1;
            height: toRem(100);
            display: flex;
            flex-direction: column;
            justify-content: center;
            .titleNum{
                width:toRem(243);
                display: flex;
                justify-content: space-between;
                .goodsTitle{
                    height: toRem(35);
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    display: -webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .goodsNum{
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    margin-right:  toRem(10);
                }
            }
            .specifications{
                height: toRem(40);
                font-size:  toRem(11);
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;
                margin-right:  toRem(10);
            }
        }
        .p_but{
            width: toRem(70);
            height: toRem(28);
            border: toRem(1)solid #FF7373;
            border-radius: toRem(14);
            font-size: toRem(12);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FF7373;
            position: absolute;
            right: toRem(16);
            bottom: toRem(20);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .refundMethod{
		background: #FFFFFF;
		margin: toRem(12) 0;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #333333;
    }
    .pattern{
        width: toRem(343);
        height: toRem(46);
        border-bottom:toRem(1) solid #F5F5F5;
        margin-left: toRem(16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .patternLeft{
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
        }
        .redText{
            color:#FF7373;
        }
    }
    .shipperInfo{
        background-color: #fff;
        height: toRem(92);
        .refundText{
            width: toRem(343);
            height: toRem(72);
            background: #F5F5F5;
            border-radius: toRem(5);
            margin: 0 toRem(16) toRem(20);
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: toRem(24);
            div{
                margin-left: toRem(16);
            }
        }
    }
    .supplement{
		background: #FFFFFF;
        .supplementTop{
            width: toRem(343);
            height:toRem(51);
            margin-left: toRem(16);
            border-bottom: toRem(1) solid #F5F5F5;
            display: flex;
            align-items: center;
            font-size: toRem(16);
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
        }
        .supplementText{
            width: toRem(343);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            padding: toRem(16);
        }
        .refundIcon{
            width: toRem(343);
            margin-left: toRem(16);
            display: flex;
            padding-bottom: toRem(20);
            .p_image{
                width: toRem(80);
                height: toRem(80);
                border-radius: toRem(8);
                margin-right: toRem(8);
            }
        }
	}
    .orderDetail{
		background: #FFFFFF;
		margin-top: toRem(12);
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #999999;
	}
	.footer{
		padding: toRem(10) 0;
		background-color: #fff;
		position: fixed;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
        right: 0;
	}
	.bottomButton{
		width: toRem(343);
		height: toRem(44);
		background: #FF982E;
		border-radius: toRem(22);
		font-size: toRem(18);
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}