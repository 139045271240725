@function toRem($a) {
    @return ($a/37.5)+rem;
}

.contentbox{
    font-size: toRem(14);
    color: #333;
    overflow:hidden;
    .goods-info{ 
        margin-top:toRem(12);
        display: flex;
        padding:toRem(14);
        background-color: #fff;
        img{
            width: toRem(100);
            height: toRem(100);
            margin-right: toRem(12);
        }
        .goodsDetails{
            height: toRem(100);
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            .titleNum{
                display: flex;
                justify-content: space-between;
                .goodsTitle{
                    height: toRem(35);
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    display: -webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .goodsNum{
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    margin-right:toRem(10);
                }
                
            }
        }
        .specifications{
            height: toRem(40);
            font-size:toRem(11);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            display: flex;
            align-items: center;
            margin-right: toRem(10);
        }
    }
    .content{
        background-color: #fff;
		margin-top:  toRem(12);
        .contentTop{
            width: toRem(343);
            height: toRem(50);
            border-bottom: toRem(1) solid #F5F5F5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: toRem(16);
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
        }
        .p_rightArrow{
            width: toRem(7);
            height: toRem(12);
            margin-left: toRem(9);
        }
        .contentLeft{
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            span{
                color: #FF7373;
            }
        }
        .contentBottom{
            width:toRem(343);
            height: toRem(70);
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: toRem(16);
            .refundAmount{
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-size: toRem(16);
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FF982E;
                .refundAmount_right{
                    display: flex;
                    align-items: center;
                }
                input{
                    color: #FF982E;
                    text-align: right;
                    font-size: toRem(16);
                }
            }
            .tips{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-size:  toRem(10);
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #999999;
            }
        }
    }
    .supplement{
        width: 100%;
		padding-bottom: toRem(20);
		background-color: #fff;
		margin-top:toRem(12);

        .supplementTop{
            width: toRem(343);
            height: toRem(51);
            margin-left: toRem(16);
            border-bottom: toRem(1) solid #F5F5F5;
            display: flex;
            align-items: center;
            font-size: toRem(16);
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;

            span{
                color: #FF7373;
            }
        }
        .supplementCenter{
            width: toRem(343);
            height: toRem(53);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin: toRem(16);
            font-size: toRem(14);
            textarea{
                font-size: toRem(14);
            }
        }

        .supplementImage{
            width: toRem(343);
            margin-left: toRem(16);
        }
	}
    .submit{
        width: toRem(343);
		height:toRem(44);
		background-color:#FF982E;
		border-radius: toRem(22);
		font-size: toRem(18);
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: toRem(70) toRem(16) toRem(44);
    }
}