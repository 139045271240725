@function toRem($a) {
    @return ($a/37.5) + rem;
}
.follow{
    font-size: toRem(14);
    color: #333;
    overflow-y: scroll;
    height: 100%;
    .tabs{
        display: flex;
        background-color: #fff;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
        div{
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: toRem(16);
            color: #999;
            height: toRem(44);
        }
        .active{
            color: #486BEB;
            font-weight: bold;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                bottom: toRem(6);
                height: toRem(3);
                width: toRem(24);
                background-color: #486BEB;
                border-radius: toRem(3);
                left:50%;
                transform: translateX(-50%);
            }
        }
    }
    .list{
        padding:0 toRem(12);
        background-color: #fff;
        width: toRem(694/2);
        margin:0 auto;
        margin-top: toRem(14);
        box-sizing: border-box;
        border-radius: toRem(8);
        .item{
            display: flex;
            justify-content: space-between;
            height: toRem(72);
            border-bottom: toRem(1) solid #EDEFF1;
            align-items: center;
            &:last-child{
                border:0;
            }
            .left{
                display: flex;
                align-items: center;
                img{
                    width: toRem(44);
                    height: toRem(44);
                    border-radius: 50%;
                    margin-right: toRem(8);
                }
                div{
                    p:nth-child(1){
                        font-weight: bold;
                        margin-bottom: toRem(5);
                    }
                    p:nth-child(2){
                        font-size: toRem(13);
                        color: #999;
                    }
                }
            }
            .right{
                width: toRem(138/2);
                height: toRem(52/2);
                border-radius: toRem(16);
                border:toRem(1) solid #EDEFF1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ccc;
            }
            .right-active{
                border-color: #486BEB;
                color: #486BEB;
            }
        }
    }
}