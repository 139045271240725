@function toRem($a) {
    @return ($a/37.5) + rem;
}
.footer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 10;
    width: toRem(375);
    height: toRem(55);
    display: flex;
    font-size: toRem(12);
    background-color: #fff;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        flex-direction: column;
        align-items: center;
        color: #bcbcbc;
        img {
            margin-bottom: toRem(5);
        }
        &:nth-child(1) img,
        &:nth-child(2) img {
            width: toRem(18.5);
            height: toRem(17.5);
        }
        &:nth-child(3) img {
            width: toRem(18);
            height: toRem(17.5);
        }
        &:nth-child(4) img {
            width: toRem(15);
            height: toRem(17.5);
        }
        &:nth-child(5) img {
            width: toRem(17.5);
            height: toRem(15.5);
        }
    }
}
