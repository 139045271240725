@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content {
    padding: 0 toRem(14);
    padding-bottom: toRem(60);
    overflow: hidden;
    .storeInfo {
        margin-top: toRem(15);
        display: flex;
        align-items: center;
        margin-bottom: toRem(29);
        img {
            width: toRem(50);
            height: toRem(50);
            border-radius: 50%;
            margin-right: toRem(12);
        }
        .title {
            div:nth-child(1) {
                color: #000;
                font-size: toRem(17);
                font-weight: bold;
                margin-bottom: toRem(7);
            }
            div:nth-child(2) {
                color: #666;
                font-size: toRem(12);
            }
        }
    }
    .storeGoodsBox {
        position: relative;
    }
    .storeGoods {
        background-color: #fff;
        border-radius: toRem(10);
        margin-bottom: toRem(10);
        .top {
            height: toRem(90);
            border-radius: toRem(10) toRem(10) 0 0;
            position: relative;
            img {
                position: absolute;
                left: 0;
                bottom: 0;
                border-radius: toRem(10) 0 0 0;
                height: 100%;
                width: toRem(95);
            }
            .address {
                position: absolute;
                z-index: 2;
                right: 0;
                top: 0;
                height: 100%;
                width: toRem(267);
                background-size: 100% 100%;
                .text {
                    color: #fff;
                    font-size: toRem(14);
                    padding: toRem(5) toRem(10) 0 toRem(22);
                }
            }
            .discount {
                position: absolute;
                z-index: 3;
                bottom: toRem(10);
                right: 0;
                color: #fff;
                div:nth-child(1) {
                    font-size: toRem(12);
                    width: toRem(72);
                    height: toRem(22);
                    line-height: toRem(22);
                    padding-left: toRem(10);
                    background: linear-gradient(90deg, #fcb029 0%, rgba(252, 176, 41, 0) 100%);
                    border-radius: toRem(13.5) 0px 0px toRem(13.5);
                    position: relative;
                    left: toRem(42);
                    top: toRem(3);
                }
                div:nth-child(2) {
                    width: toRem(115);
                    line-height: toRem(27);
                    padding-left: toRem(14);
                    font-size: toRem(12);
                    background: linear-gradient(90deg, rgba(59, 108, 245, 0.78) 22%, rgba(59, 108, 245, 0) 100%);
                    border-radius: toRem(13.5) 0px 0px toRem(13.5);
                }
            }
        }
        .bot {
            padding: toRem(15) toRem(8);
            .goods {
                display: flex;
                justify-content: space-between;
                padding-bottom: toRem(15);
                border-bottom: toRem(1) solid #eee;
                margin-bottom: toRem(15);
                .item {
                    width: toRem(80);
                    img {
                        width: 100%;
                        height: toRem(80);
                        border-radius: toRem(5);
                    }
                    div:nth-child(2) {
                        margin-top: toRem(5);
                        color: #222;
                        font-size: toRem(13);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    div:nth-child(3) {
                        font-size: toRem(16);
                        color: #ff0000;
                        font-weight: bold;
                        span {
                            font-size: toRem(12);
                            font-weight: 400;
                        }
                    }
                    div:nth-child(4) {
                        font-size: toRem(12);
                        color: #999;
                        text-decoration: line-through;
                    }
                }
            }
            .more {
                text-align: center;
                font-size: toRem(13);
                color: #555;
            }
        }
    }
    .service {
        display: flex;
        justify-content: center;
        margin-bottom: toRem(22);
        .left {
            div:nth-child(1) {
                width: toRem(225);
                height: toRem(50);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #3b6cf5;
                border-radius: toRem(25);
                color: #fff;
                font-size: toRem(16);
                font-weight: 500;
                margin-bottom: toRem(10);
                position: relative;
                img {
                    width: toRem(15.5);
                    height: toRem(17.5);
                    margin-right: toRem(7);
                }
                font-size: toRem(16);
                font-weight: 500;
            }
            div:nth-child(2) {
                font-size: toRem(12);
                color: #666;
                text-align: center;
            }
        }
    }
    .shopLogo {
        padding: toRem(19) toRem(16.5);
        background-color: #fff;
        border-radius: toRem(10);
        margin-bottom: toRem(7);
        .title {
            color: #222;
            font-size: toRem(17);
            font-weight: 600;
            margin-bottom: toRem(21);
        }
        .logoItem {
            display: flex;
            padding-bottom: toRem(24);
            img {
                margin-right: toRem(17);
                width: toRem(49);
                height: toRem(49);
                border-radius: 50%;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .storeLogo2 {
        margin-bottom: toRem(10);
        div:first-child {
            display: flex;
            justify-content: space-between;
            margin-bottom: toRem(7);
            img {
                width: toRem(170);
                height: toRem(130);
            }
        }
        div:last-child {
            display: flex;
            justify-content: space-between;
            img {
                width: toRem(111);
                height: toRem(130);
            }
        }
    }
    .coupon {
        background-color: #fff;
        border-radius: toRem(10);
        padding: toRem(15) 0;
        margin-bottom: toRem(20);
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 toRem(16);
            margin-bottom: toRem(15);
            div:nth-child(1) {
                span:nth-child(1) {
                    color: #222;
                    font-size: toRem(17);
                    margin-right: toRem(5);
                    font-weight: 600;
                }
                span:nth-child(2) {
                    font-size: toRem(12);
                    color: #999;
                }
            }
            div:nth-child(2) {
                font-size: toRem(12);
                color: #999;
            }
        }
        .couponGoods {
            display: flex;
            overflow-x: auto;
            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
            .item {
                margin-right: toRem(10);
                &:first-child {
                    margin-left: toRem(16);
                }
                img {
                    width: toRem(80);
                    height: toRem(80);
                    border-radius: toRem(5);
                }
                div:nth-child(2) {
                    color: #222;
                    font-size: toRem(13);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-top: toRem(3);
                    width: toRem(80);
                }
                div:nth-child(3) {
                    color: #ff0000;
                    font-weight: bold;
                    font-size: toRem(16);
                    span {
                        font-size: toRem(10);
                        font-weight: 400;
                    }
                }
                div:nth-child(4) {
                    color: #999;
                    font-size: toRem(10);
                    text-decoration: line-through;
                }
            }
        }
    }
    .recommendGoods {
        .title {
            margin-bottom: toRem(15);
            text-align: center;
            position: relative;
            div:nth-child(1) {
                color: #222;
                font-size: toRem(17);
                font-weight: bold;
            }
            div:nth-child(2) {
                font-size: toRem(12);
                color: #666;
            }
            img {
                width: toRem(34);
                height: toRem(23);
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            .goodsItem {
                width: toRem(167.5);
                padding-bottom: toRem(15);
                background-color: #fff;
                border-radius: toRem(7);
                margin-bottom: toRem(11);
                &:nth-child(odd) {
                    margin-right: toRem(11);
                }
                img {
                    border-radius: toRem(7) toRem(7) 0 0;
                    width: 100%;
                    height: toRem(150);
                }
                .goodsTitle {
                    color: #000;
                    padding: 0 toRem(11);
                    margin-top: toRem(5);
                    margin-bottom: toRem(10);
                    height: toRem(35);
                    font-size: toRem(14);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .goodsPrice {
                    padding-left: toRem(11);
                    span:nth-child(1) {
                        font-size: toRem(11);
                        color: #ff0000;
                    }
                    span:nth-child(2) {
                        font-size: toRem(18);
                        color: #ff0000;
                        font-weight: bold;
                    }
                    span:nth-child(3) {
                        font-size: toRem(10);
                        color: #999;
                        margin-left: toRem(5);
                        text-decoration: line-through;
                    }
                }
            }
        }
        .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: toRem(12);
            color: #999;
            margin: toRem(15) 0;
            margin-left: toRem(7);
        }
    }
    .webpage{
        background-color: #fff;
        border-radius: toRem(10);
        padding:toRem(15);
        margin-bottom: toRem(10);
        display: flex;
        justify-content: space-between;
        .left{
            img{
                width: toRem(152.5);
                height: toRem(118);
            }
        }
        .right{
            .top img{
                width: toRem(151.5);
                height: toRem(38);
            }
            .bot{
                margin-top: toRem(10);
                display: flex;
                justify-content: space-between;
                img{
                    width: toRem(71);
                    height: toRem(65.5);
                }
            }
        }
    }
}
