@function toRem($a) {
    @return ($a/37.5) + rem;
}

.logistics-info {
    height: 100%;
    background-color: #fff;
    overflow-y: scroll;
    font-size: toRem(14);
    color: #333;
    .header {
        height: toRem(158/2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #999;
        padding-left: toRem(33/2);
        border-bottom: toRem(1) solid #f5f5f5;
        margin-bottom: toRem(25);
        .order-no {
            display: flex;
            align-items: center;
        }
        .headerRight {
            margin-right: toRem(38/2);
        }
        .copyIcon {
            width: toRem(29/2);
            height: toRem(29/2);
            margin-right: toRem(9/2);
        }
        .copyText {
            color: #ff5353;
        }
    }
    .content {
        display: flex;
        .contentLeft {
            width: toRem(148/2);
            height: toRem(170/2);
            display: flex;
            flex-direction: column;
            align-items: center;
            .p_date {
                font-size: toRem(16);
            }
            .p_time {
                font-size: toRem(11);
                color: #999999;
            }
        }
        .icons {
            width: toRem(25);
            display: flex;
            flex-direction: column;
            align-items: center;
            .p_icon {
                width: toRem(24);
                height: toRem(24);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: #ff5353;
            }
            .p_icons {
                width: toRem(24);
                height: toRem(24);
            }
            .icon {
                width: toRem(11);
                height: toRem(8);
            }
            .line {
                width: toRem(1);
                height: toRem(61);
                background: #e6e6e6;
            }
        }
        .contentRight {
            width: toRem(520/2);
            margin-left: toRem(12);
            .statusText {
                font-size: toRem(16);
            }
            .context {
                font-size: toRem(11);
                color: #999;
                margin-top: toRem(4);
            }
        }
    }
}
