@function toRem($a) {
    @return ($a/37.5) + rem;
}

.settlement-apply {
    font-size: toRem(14);
    color: #333;
    padding: toRem(12);
    .speed {
        background-color: #fff;
        height: toRem(94);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: toRem(12);
        margin-bottom: toRem(10);
        div:nth-child(1) {
            display: flex;
            align-items: center;
            font-size: toRem(11);
            color: #ccc;
            margin-bottom: toRem(16);
            span:nth-child(1),
            span:nth-child(3),
            span:nth-child(5) {
                width: toRem(18);
                height: toRem(18);
                border-radius: 50%;
                border: toRem(1) solid #cccccc;
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
            }
            span:nth-child(2),
            span:nth-child(4) {
                width: toRem(68);
                height: toRem(1);
                background-color: #ccc;
                margin: 0 toRem(15);
            }
        }
        div:nth-child(2) {
            display: flex;
            color: #ccc;
            width: 100%;
            span {
                flex: 1;
                text-align: center;
            }
        }
        .speed-active1{
            background-color: #FF5A01;
            border: 0!important;
            color: #fff;
        }
        .speed-active2{
            color: #FF5A01;
            font-weight: bold;
        }
    }
    .apply-form {
        margin-bottom: toRem(70);
        .personal-info {
            background-color: #fff;
            border-radius: toRem(12);
            padding: toRem(20) toRem(12);
            margin-bottom: toRem(23);
            .form-item {
                margin-bottom: toRem(25);
                border-bottom: toRem(1) solid #f2f2f2;
                padding-bottom: toRem(6);
                input {
                    font-size: toRem(14);
                }
                .title {
                    font-weight: bold;
                    font-size: toRem(15);
                    margin-bottom: toRem(17);
                    &:before {
                        content: "*";
                        color: #ff5a01;
                    }
                }
                &:first-child {
                    .title:after {
                        content: "（需是已微信实名认证的） ";
                        color: #999;
                        font-size: toRem(13);
                        font-weight: 500;
                    }
                }
                &:last-child {
                    margin: 0;
                }
            }
        }
        .bank-type {
            padding: 0 toRem(12);
            margin-bottom: toRem(12);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                font-size: toRem(15);
                font-weight: bold;
            }
            .right {
                display: flex;
                font-size: toRem(15);
                align-items: center;
                div {
                    display: flex;
                    align-items: center;
                    margin-left: toRem(20);
                }
                .circle1 {
                    width: toRem(18);
                    height: toRem(18);
                    border-radius: 50%;
                    border: toRem(1) solid #cccccc;
                    box-sizing: border-box;
                    margin-right: toRem(6);
                }
                .circle2 {
                    width: toRem(18);
                    height: toRem(18);
                    border-radius: 50%;
                    background-color: #ff5a01;
                    display: flex;
                    align-items: center;
                    margin-right: toRem(6);
                    justify-content: center;
                    span {
                        width: toRem(9);
                        height: toRem(9);
                        border-radius: 50%;
                        background-color: #fff;
                    }
                }
            }
        }
        .bank-info {
            background-color: #fff;
            border-radius: toRem(12);
            padding: toRem(20) toRem(12);
            margin-bottom: toRem(23);
            .form-item {
                margin-bottom: toRem(25);
                border-bottom: toRem(1) solid #f2f2f2;
                padding-bottom: toRem(6);
                input {
                    font-size: toRem(14);
                }
                .title {
                    font-weight: bold;
                    font-size: toRem(15);
                    margin-bottom: toRem(17);
                    &:before {
                        content: "*";
                        color: #ff5a01;
                    }
                    span {
                        color: #999;
                        font-size: toRem(13);
                        font-weight: 500;
                    }
                }
                &:last-child {
                    margin: 0;
                }
                .address {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

            }
            .certificates {
                border: 0;
                .card {
                    display: flex;
                    justify-content: space-between;
                    .card-front,
                    .card-back {
                        width: toRem(159);
                        height: toRem(159);
                        background-color: #f7f9fa;
                        border-radius: toRem(8);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .card-front-img,
                        .card-back-img {
                            position: relative;
                            img:nth-child(1) {
                                width: toRem(118);
                                height: toRem(76.45);
                                object-fit: cover;
                            }
                            img:nth-child(2) {
                                width: toRem(16);
                                height: toRem(16);
                                position: absolute;
                                top: toRem(-8);
                                right: toRem(-8);
                            }
                        }
                        .add {
                            position: relative;
                            img {
                                width: toRem(118);
                                height: toRem(76.45);
                            }
                            input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                opacity: 0;
                            }
                        }
                        p {
                            font-size: toRem(13);
                            color: #666;
                            margin-top: toRem(10);
                        }
                    }
                }
            }
            .contract {
                border: 0;
                .contract-list {
                    display: flex;
                    position: relative;
                    .item {
                        width: toRem(100);
                        height: toRem(100);
                        border-radius: toRem(8);
                        margin-right: toRem(10);
                        position: relative;
                        &:last-child {
                            margin: 0;
                        }
                        .contract-img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: toRem(8);
                        }
                        .remove {
                            position: absolute;
                            width: toRem(16);
                            height: toRem(16);
                            position: absolute;
                            top: toRem(-8);
                            right: toRem(-8);
                        }
                    }
                    .add {
                        border: toRem(1) dashed #cccccc;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: toRem(23);
                            height: toRem(22.16);
                        }
                        p {
                            margin-top: toRem(5);
                            color: #ccc;
                            font-size: toRem(13);
                        }
                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                }
            }
            .business-license {
                border: 0;
                .business-license-img {
                    position: relative;
                    img:nth-child(1) {
                        width: 100%;
                        height: toRem(180);
                        object-fit: cover;
                        border-radius: toRem(8);
                    }
                    img:nth-child(2) {
                        position: absolute;
                        width: toRem(16);
                        height: toRem(16);
                        position: absolute;
                        top: toRem(-8);
                        right: toRem(-8);
                    }
                }
                .add {
                    border: toRem(1) dashed #cccccc;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: toRem(180);
                    border-radius: toRem(8);
                    position: relative;
                    img {
                        width: toRem(23);
                        height: toRem(22.16);
                    }
                    p {
                        margin-top: toRem(5);
                        color: #ccc;
                        font-size: toRem(13);
                    }
                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                    }
                }
            }
        }
        .shop-infos {
            background-color: #fff;
            border-radius: toRem(12);
            padding: toRem(20) toRem(12);
            margin-bottom: toRem(23);
            .form-item {
                margin-bottom: toRem(25);
                border-bottom: toRem(1) solid #f2f2f2;
                padding-bottom: toRem(6);
                input {
                    font-size: toRem(14);
                }
                textarea{
                    font-size: toRem(14);
                }
                .title {
                    font-weight: bold;
                    font-size: toRem(15);
                    margin-bottom: toRem(17);
                    &:before {
                        content: "*";
                        color: #ff5a01;
                    }
                    span {
                        color: #999;
                        font-size: toRem(13);
                        font-weight: 500;
                    }

                }
                &:nth-child(2) .title:before{
                    content: "";
                }
                &:nth-child(4) .title:before{
                    content: "";
                }
                &:last-child {
                    margin: 0;
                }
                .address {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
            .flex_row{
                display: flex;
                .title {
                    font-weight: bold;
                    font-size: toRem(15);
                    margin-bottom: toRem(17);
                    flex: 1;
                    &:before {
                        content: "*";
                        color: #ff5a01;
                    }
                    span {
                        color: #999;
                        font-size: toRem(13);
                        font-weight: 500;
                    }

                }
            }
            .tips{
                color:#666;
                font-size: toRem(13);
            }
            .banner {
                border: 0;
                .contract-list {
                    display: flex;
                    position: relative;
                    .item {
                        width: toRem(100);
                        height: toRem(100);
                        border-radius: toRem(8);
                        margin-right: toRem(10);
                        position: relative;
                        &:last-child {
                            margin: 0;
                        }
                        .contract-img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: toRem(8);
                        }
                        .remove {
                            position: absolute;
                            width: toRem(16);
                            height: toRem(16);
                            position: absolute;
                            top: toRem(-8);
                            right: toRem(-8);
                        }
                    }
                    .add {
                        border: toRem(1) dashed #cccccc;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: toRem(23);
                            height: toRem(22.16);
                        }
                        p {
                            margin-top: toRem(5);
                            color: #ccc;
                            font-size: toRem(13);
                        }
                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                }
            }
            .logo {
                border: 0;
                .addLogo {
                    display: flex;
                    padding: 0 toRem(22);
                    justify-content: space-between;
                    color: #333;
                    font-size: toRem(14);
                    .demonstration {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        img {
                            width: toRem(80);
                            height: toRem(80);
                            border-radius: 50%;
                            margin-bottom: toRem(10);
                        }
                    }
                    .upLogo {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .add {
                            width: toRem(80);
                            height: toRem(80);
                            border-radius: 50%;
                            border: toRem(1) dashed #ccc;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-size: toRem(13);
                            color: #ccc;
                            justify-content: center;
                            box-sizing: border-box;
                            margin-bottom: toRem(10);
                            position: relative;
                            input {
                                position: absolute;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 2;
                                opacity: 0;
                            }
                            img {
                                width: toRem(23);
                                height: toRem(22.16);
                                margin-bottom: toRem(2);
                            }
                        }
                        .logo {
                            width: toRem(80);
                            height: toRem(80);
                            border-radius: 50%;
                            position: relative;
                            margin-bottom: toRem(10);

                            img {
                                width: toRem(80);
                                height: toRem(80);
                                border-radius: 50%;
                            }
                            .close {
                                position: absolute;
                                top: toRem(-5);
                                right: toRem(-5);
                            }
                        }
                    }
                }
            }
        }
        .category {
            margin-bottom: toRem(10);
            font-weight: bold;
            font-size: toRem(15);
            padding-left: toRem(12);
            span {
                color: #999;
                font-size: toRem(13);
                font-weight: 500;
            }
        }
        .category-qualifications {
            background-color: #fff;
            border-radius: toRem(12);
            padding: toRem(20) toRem(12);
            margin-bottom: toRem(23);
            .form-item {
                margin-bottom: toRem(25);
                border-bottom: toRem(1) solid #f2f2f2;
                padding-bottom: toRem(6);
                input {
                    font-size: toRem(14);
                }
                .title {
                    font-weight: bold;
                    font-size: toRem(15);
                    margin-bottom: toRem(17);
                    &:before {
                        content: "*";
                        color: #ff5a01;
                    }
                    span {
                        color: #999;
                        font-size: toRem(13);
                        font-weight: 500;
                    }
                }
                &:last-child {
                    margin: 0;
                }
                &:nth-child(2) .title:before{
                    content: "";
                }
                .address {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
            .qualification {
                border: 0;
                .qualification-list {
                    display: flex;
                    position: relative;
                    .item {
                        width: toRem(100);
                        height: toRem(100);
                        border-radius: toRem(8);
                        margin-right: toRem(10);
                        position: relative;
                        &:last-child {
                            margin: 0;
                        }
                      
                        .qualification-img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: toRem(8);
                        }
                        .remove {
                            position: absolute;
                            width: toRem(16);
                            height: toRem(16);
                            position: absolute;
                            top: toRem(-8);
                            right: toRem(-8);
                        }
                    }
                    .add {
                        border: toRem(1) dashed #cccccc;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: toRem(23);
                            height: toRem(22.16);
                        }
                        p {
                            margin-top: toRem(5);
                            color: #ccc;
                            font-size: toRem(13);
                        }
                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
    .btn{
        position: fixed;
        bottom: 0;
        left:0;
        width: 100%;
        background-color: #fff;
        z-index: 10;
        padding:toRem(10) toRem(12);
        display: flex;
        box-sizing: border-box;
        div{
            line-height: toRem(40);
            text-align: center;
        }
        .top{
            width: toRem(82);
            border:toRem(1) solid #E5E5E5;
            box-sizing: border-box;
            margin-right: toRem(9);
            border-radius: toRem(20);
        }
        .bot{
            flex:1;
            background-color: #FF5A01;
            border-radius: toRem(25);
            color: #fff;
        }
    }
}
