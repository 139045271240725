@function toRem($a) {
    @return ($a/37.5) + rem;
}

.integral-order {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y:scroll;
    .tabs {
        background-color: #fff;
        display: flex;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
        margin-bottom: toRem(10);
        div {
            height: toRem(50);
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999;
        }
        .active {
            color: #ff5a01;
            font-size: toRem(16);
        }
    }
    .list {
        padding: 0 toRem(12);
        .item {
            background-color: #fff;
            border-radius: toRem(12);
            padding: toRem(12);
            margin-bottom: toRem(10);
            .order-no {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: toRem(10);
                span:nth-child(1) {
                    flex: 1;
                    font-size: toRem(15);
                    font-weight: bold;
                }
                span:nth-child(2) {
                    color: #ff5a01;
                }
            }
            .goods-info {
                display: flex;
                img {
                    width: toRem(80);
                    height: toRem(80);
                    margin-right: toRem(12);
                    border-radius: toRem(6);
                }
                .goods-con {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .goods-title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .goods-price {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        div:nth-child(2) {
                            color: #999;
                            font-size: toRem(13);
                        }
                    }
                }
            }
            .time {
                text-align: right;
                margin-top: toRem(12);
                color: #999;
                font-size: toRem(13);
            }
            .total-price {
                text-align: right;
                font-size: toRem(12);
                margin-top: toRem(4);
                span:nth-child(2) {
                    color: #ff5a01;
                    font-size: toRem(16);
                }
            }
            .btn {
                display: flex;
                justify-content: flex-end;
                margin-top: toRem(14);
                div {
                    margin-left: toRem(10);
                    width: toRem(88);
                    height: toRem(34);
                    border-radius: toRem(17);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                }
                .cancel,
                .logistics {
                    border: toRem(1) solid #e5e5e5;
                }
                .pay,
                .confirm {
                    border: toRem(1) solid #ff5a01;
                    color: #ff5a01;
                }
            }
        }
        .no{
            margin:toRem(20) auto;
            text-align: center;
            color: #ccc;
            font-size: toRem(12);
        }
    }
}
