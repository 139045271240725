@function toRem($a) {
    @return ($a/37.5) + rem;
}

.goods-img {
    position: relative;
    height: toRem(375);
    margin-bottom: toRem(8);
    .customIndicator {
        position: absolute;
        bottom: toRem(16);
        right: toRem(16);
        width: toRem(40);
        height: toRem(21);
        background: rgba(0, 0, 0, 0.4);
        border-radius: toRem(15);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: toRem(12);
    }
}
.goods-info {
    background-color: #fff;
    border-radius: toRem(12);
    font-size: toRem(14);
    color: #333;
    padding: toRem(15);
    margin-bottom: toRem(8);
    .price {
        display: flex;
        align-items: center;
        margin-bottom: toRem(8);
        justify-content: space-between;
        div:nth-child(1) {
            display: flex;
            align-items: center;
            p:nth-child(1) {
                font-weight: bold;
                color: #f84646;
                font-size: toRem(24);
                margin-right: toRem(11);
                &:before {
                    content: "￥";
                    font-size: toRem(14);
                }
            }
            p:nth-child(2) {
                color: #ccc;
                font-size: toRem(12);
                text-decoration: line-through;
            }
        }
        div:nth-child(2) {
            color: #ccc;
            font-size: toRem(12);
        }
    }
    .goods-name {
        font-size: toRem(16);
        font-weight: bold;
    }
    .sub-title {
        font-size: toRem(13);
        color: #999;
        margin-top: toRem(5);
    }
}

.shop-info {
    padding: toRem(15);
    background-color: #fff;
    border-radius: toRem(12);
    font-size: toRem(14);
    margin-bottom: toRem(8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    div:nth-child(1) {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: toRem(16);
        img {
            width: toRem(48);
            height: toRem(48);
            border-radius: toRem(8);
            margin-right: toRem(12);
        }
    }
    div:nth-child(2) {
        width: toRem(80);
        height: toRem(32);
        box-sizing: border-box;
        border: toRem(1) solid #333;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: toRem(12);
        border-radius: toRem(16);
    }
}
.goods-details {
    background-color: #fff;
    padding: toRem(15);
    border-radius: toRem(12);
    padding-bottom: toRem(70);
    .title {
        color: #333;
        font-size: toRem(16);
        text-align: center;
        margin-bottom: toRem(12);
    }
    img {
        width: 100%;
    }
}
.operate-wrap {
    position: fixed;
    background-color: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: toRem(8) toRem(15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    .left {
        display: flex;
        align-items: center;
        div {
            margin-right: toRem(21);
            text-align: center;
            font-size: toRem(10);
            color: #666;
            position: relative;
            &:nth-child(1) img {
                width: toRem(18);
                height: toRem(17);
            }
            &:nth-child(2) img {
                width: toRem(18);
                height: toRem(15);
            }
            &:nth-child(2) p {
                padding-top: toRem(2);
            }
            .cart-num {
                position: absolute;
                left: toRem(16);
                top: toRem(-6);
                font-size: toRem(8);
                color: #fff;
                text-align: center;
                width: toRem(16);
                height: toRem(12);
                background: #ff7373;
                border: toRem(1) solid #ffffff;
                border-radius: toRem(10);
                line-height: toRem(12);
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
        div {
            width: toRem(110);
            line-height: toRem(44);
            color: #fff;
            font-size: toRem(12);
            text-align: center;
            &:nth-child(1) {
                background-color: #ffd1ce;
                border-radius: toRem(22) 0px 0px toRem(22);
            }
            &:nth-child(2) {
                background-color: #ff1607;
                border-radius: 0 toRem(22) toRem(22) 0;
            }
        }
    }
}
.spec-wrap {
    padding-top: toRem(20);
    color: #333;
    font-size: toRem(14);
    .spec-goosd-info {
        padding: 0 toRem(15);
        display: flex;
        align-items: center;
        img {
            width: toRem(80);
            height: toRem(80);
            border-radius: toRem(4);
            margin-right: toRem(10);
        }
        .name-price {
            flex: 1;
            div:nth-child(1) {
                margin-bottom: toRem(13);
                font-size: toRem(16);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            div:nth-child(2) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    font-weight: bold;
                    color: #ff1607;
                    span {
                        text-decoration: line-through;
                        color: #ccc;
                        font-size: toRem(12);
                        margin-left: toRem(10);
                        font-weight: 500;
                    }
                }
                span:nth-child(2) {
                    font-size: toRem(12);
                }
            }
        }
    }
    .spec-info {
        padding: 0 toRem(15);
        margin-top: toRem(20);
        max-height: toRem(295);
        box-sizing: border-box;
        overflow-y: scroll;
        .line {
            height: toRem(1);
            background-color: #f5f5f5;
            margin-bottom: toRem(15);
            position: sticky;
            left: 0;
            top: 0;
            z-index: 3;
        }
        .spec-list {
            margin-bottom: toRem(10);
            .title {
                margin-bottom: toRem(15);
            }
            .details {
                display: flex;
                flex-wrap: wrap;
                .item {
                    height: toRem(28);
                    display: flex;
                    align-items: center;
                    padding: 0 toRem(12);
                    border-radius: toRem(8);
                    margin-right: toRem(12);
                    background-color: #f7f7fa;
                    margin-bottom: toRem(12);
                }
                .active {
                    background-color: #ff1607;
                    color: #fff;
                }
            }
        }
        .add-num {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: toRem(22);
        }
    }
    .operate-btn {
        padding: toRem(8) toRem(15);
        border-top: toRem(1) solid #f0f5f5;
        display: flex;
        justify-content: center;
        div {
            width: toRem(343/2);
            line-height: toRem(44);
            color: #fff;
            font-size: toRem(12);
            text-align: center;
            &:nth-child(1) {
                background-color: #ffd1ce;
                border-radius: toRem(22) 0px 0px toRem(22);
            }
            &:nth-child(2) {
                background-color: #ff1607;
                border-radius: 0 toRem(22) toRem(22) 0;
            }
        }
    }
}
