@function toRem($a) {
    @return ($a/37.5) + rem;
}

.content{
    .back img{
        width: 100%;
        height: toRem(162);
    }
    .wallet_con{
        position: relative;
        top:toRem(-108);
        width: toRem(347);
        margin:0 auto;
        .wallet_money{
            background-color: #fff;
            border-radius: toRem(8);
            padding:toRem(15) toRem(13);
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #333;
                margin-bottom: toRem(30);
                div:nth-child(1){
                    font-size: toRem(14);
                }
                div:nth-child(2){
                    font-size: toRem(12);
                    display: flex;
                    align-items: center;
                    img{
                        width: toRem(11);
                        height: toRem(12);
                        margin-right: toRem(3);
                    }
                }
            }
            .num{
                display: flex;
                align-items: center;
                justify-content: space-between;
                div:nth-child(1){
                    color: #333;
                    font-weight: bold;
                    span:nth-child(1){
                        font-size: toRem(23);
                    }
                    span:nth-child(2){
                        font-size: toRem(44);
                    }
                }
                div:nth-child(2){
                    color: #ccc;
                    font-size: toRem(14);
                }
            }
            .btn{
                margin:0 auto;
                width: toRem(293/2);
                height: toRem(82/2);
                margin-top: toRem(30);
                background-color: #3B6CF5;
                border-radius: toRem(42/2);
                color: #fff;
                font-size: toRem(14);
                text-align: center;
                line-height: toRem(82/2);
            }
        }
        .wallet_detail{
            margin-top: toRem(13);
            background-color: #fff;
            border-radius: toRem(8);
            padding:0 toRem(13);
            .title{
                height: toRem(47);
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom:toRem(1) solid #F4F7FC;
                div:nth-child(1){
                    color: #333;
                    font-weight: bold;
                    font-size: toRem(16);
                }
                div:nth-child(2){
                    color: #999;
                }
            }
            .list{
                .item{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: toRem(13) 0;
                    border-bottom:toRem(1) solid #F4F7FC;
                    &:last-child{
                        border: 0;
                    }
                    .left{
                        div:nth-child(1){
                            font-size: toRem(14);
                            color: #333;
                            margin-bottom: toRem(5);
                        }
                        div:nth-child(2){
                            font-size: toRem(12);
                            color: #999;
                        }
                    }
                    .right{
                        font-size: toRem(18);
                        font-weight: bold;
                        color: #A670FF;
                    }
                }
            }
        }
    }
}