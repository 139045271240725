@function toRem($a) {
    @return ($a/37.5) + rem;
}
.empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: toRem(14);
    color: #333;
    margin:0 auto;
    img{
        width: toRem(200);
        height: toRem(143);
        margin-bottom: toRem(15);
    }
}