@function toRem($a) {
    @return ($a/37.5) + rem;
}

.cart {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y: scroll;
    .total-num {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        margin-bottom: toRem(8);
        line-height: toRem(44);
        padding: 0 toRem(15);
    }
    .list {
        margin-bottom: toRem(30);
        .shop {
            background-color: #fff;
            border-radius: toRem(12);
            margin-bottom: toRem(8);
            padding: toRem(15);
            .shop-name {
                display: flex;
                align-items: center;
                margin-bottom: toRem(13);
                .icon1 {
                    width: toRem(18);
                    height: toRem(16);
                    margin-left: toRem(12);
                    margin-right: toRem(7);
                }
                .icon2 {
                    width: toRem(6.31);
                    height: toRem(11);
                    margin-left: toRem(5);
                }
                .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                    background-color: #ff1607;
                    border-color: #ff1607;
                }
            }
            .adm-button {
                font-size: toRem(14);
                border-radius: 0 toRem(4) toRem(4) 0;

            }
            .item {
                display: flex;
                margin-bottom: toRem(8);
                .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                    background-color: #ff1607;
                    border-color: #ff1607;
                }
                img {
                    width: toRem(100);
                    height: toRem(100);
                    border-radius: toRem(8);
                    margin-left: toRem(12);
                }
                .goods-con {
                    flex: 1;
                    height: toRem(100);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: toRem(12);
                    .title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .price-num {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .price {
                            color: #ff1607;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .cart-no {
            color: #666;
            text-align: center;
            img {
                width: toRem(383/2);
                height: toRem(252/2);
            }
            p{
                margin-top:toRem(10);
            }
        }
    }
    .random-goods {
        padding: 0 toRem(15);
        padding-bottom: toRem(70);
        .title {
            font-size: toRem(16);
            font-weight: bold;
            text-align: center;
            margin-bottom: toRem(15);
        }
        .no{
            font-size: toRem(12);
            color: #999;
            text-align: center;
            margin-top:toRem(10);
            margin-bottom: toRem(5);
        }
        .random-goods-list {
            display: flex;
            flex-wrap: wrap;
            .item {
                width: toRem(332/2);
                background-color: #fff;
                margin-bottom: toRem(11);
                border-radius: toRem(8);
                box-sizing: border-box;
                margin-right: toRem(11);
                &:nth-child(even) {
                    margin-right: 0;
                }
                img {
                    height: toRem(332/2);
                    border-radius: toRem(8) toRem(8) 0 0;
                    width: 100%;
                    object-fit: cover;
                }
                .goods-con {
                    padding: toRem(8) toRem(25/2);
                    height: toRem(208/2);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    div:nth-child(1) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    div:nth-child(2) {
                        font-size: toRem(11);
                        color: #999;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    div:nth-child(3) {
                        color: #ff1607;
                        font-weight: bold;
                        &:before {
                            content: "￥";
                            font-size: toRem(12);
                        }
                    }
                }
            }
        }
    }
    .operate {
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: #fff;
        height: toRem(60);
        padding: 0 toRem(15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        .left {
            display: flex;
            align-items: center;
            .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                background-color: #ff1607;
                border-color: #ff1607;
            }
            .total-price {
                margin-left: toRem(15);
                span {
                    color: #ff1607;
                    font-weight: bold;
                }
            }
            .check-num {
                margin-left: toRem(15);
            }
        }
        .right {
            .settlement {
                line-height: toRem(44);
                text-align: center;
                color: #fff;
                border-radius: toRem(22);
                background-color: #ff1607;
                width: toRem(100);
            }
            .remove {
                display: flex;
                align-items: center;
                height: toRem(44);
                justify-content: center;
                color: #ff1607;
                border-radius: toRem(22);
                box-sizing: border-box;
                border: toRem(1) solid #ff1607;
                width: toRem(100);
            }
        }
    }
}
