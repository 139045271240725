@function toRem($a) {
    @return ($a/37.5) + rem;
}

.goods-img {
    position: relative;
    height: toRem(350);
    margin-bottom: toRem(10);
    .customIndicator {
        position: absolute;
        bottom: toRem(9);
        right: toRem(15);
        width: toRem(40);
        height: toRem(21);
        background: rgba(0, 0, 0, 0.4);
        border-radius: toRem(15);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: toRem(12);
    }
}
.goods-info {
    margin: 0 auto;
    font-size: toRem(14);
    color: #333;
    width: toRem(345);
    background-color: #fff;
    border-radius: toRem(8);
    margin-bottom: toRem(10);
    padding: toRem(10);
    box-sizing: border-box;
    .goods-title {
        font-size: toRem(16);
        font-weight: bold;
        line-height: toRem(27);
        margin-bottom: toRem(4);
    }
    .goods-price {
        display: flex;
        justify-content: space-between;
        color: #999;
        font-size: toRem(13);
        margin-bottom: toRem(7);
        span:nth-child(1) {
            text-decoration: line-through;
        }
    }
    .integral-price {
        background: linear-gradient(92deg, #ff9001 0%, #ff5a01 100%);
        border-radius: toRem(4);
        height: toRem(66);
        box-sizing: border-box;
        padding: 0 toRem(15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            color: #fff;
            div:nth-child(1) {
                font-size: toRem(12);
                margin-bottom: toRem(5);
            }
            div:nth-child(2) {
                span:nth-child(1) {
                    font-size: toRem(18);
                    font-weight: bold;
                }
            }
        }
        .right {
            width: toRem(88);
            height: toRem(30);
            background: #ffffff;
            border-radius: toRem(25);
            color: #fa6f2f;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.goods-details {
    padding: 0 toRem(15);
    padding-bottom: toRem(70);
    img {
        width: 100%;
    }
}
.btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: toRem(60);
    background-color: #fff;
    padding: toRem(8) toRem(15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .integral-price {
        color: #ff2626;
        font-size: toRem(14);
        span:nth-child(1) {
            font-size: toRem(18);
            font-weight: bold;
        }
    }
    .exchange {
        width: toRem(154);
        height: toRem(44);
        background: #ff5a01;
        border-radius:toRem(22);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: toRem(16);
        font-weight: bold;
    }
}
