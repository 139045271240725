@function toRem($a) {
    @return ($a/37.5) + rem;
}

.content {
    color: #333;
    font-size: toRem(14);
    overflow: hidden;
    padding: 0 toRem(15);
    .address-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: toRem(8);
        padding: toRem(13) toRem(15);
        margin-top: toRem(10);
        margin-bottom: toRem(10);
        .left {
            display: flex;
            align-items: center;
            margin-right: toRem(33);
            flex: 1;
            img {
                width: toRem(24);
                height: toRem(24);
                margin-right: toRem(15);
            }
        }
        .right img {
            width: toRem(6.31);
            height: toRem(12);
        }
    }
    .goods-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: toRem(15);
        background-color: #fff;
        border-radius: toRem(8);
        margin-bottom: toRem(10);
        .goods-img {
            width: toRem(80);
            height: toRem(80);
            margin-right: toRem(15);
        }
        .goods-con {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: toRem(80);
            .goods-title {
                display: flex;
                div:nth-child(1) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    flex: 1;
                }
                div:nth-child(2) {
                    font-size: toRem(12);
                    color: #999;
                    padding-top: toRem(2);
                }
            }
            .integral-price {
                font-size: toRem(14);
                span:nth-child(1) {
                    font-weight: bold;
                }
                span:nth-child(2) {
                    font-size: toRem(12);
                }
            }
        }
    }
    .goods-price {
        background-color: #fff;
        border-radius: toRem(8);
        margin-bottom: toRem(10);
        padding: toRem(15);
        .freight {
            display: flex;
            justify-content: space-between;
            margin-bottom: toRem(15);
            span:nth-child(2) {
                font-weight: bold;
            }
        }
        .integral-price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            div:nth-child(2) {
                color: #ff2626;
                font-size: toRem(14);
                span:nth-child(1) {
                    font-size: toRem(18);
                    font-weight: bold;
                }
            }
        }
    }
    .remarks {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        border-radius: toRem(8);
        padding: toRem(15);
        align-items: center;
        .put {
            flex: 1;
            margin-left: toRem(20);
            input {
                font-size: toRem(14);
            }
        }
    }
    .btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: toRem(60);
        background-color: #fff;
        padding: toRem(8) toRem(15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .integral {
            span:nth-child(1) {
                font-size: toRem(12);
            }
            span:nth-child(2) {
                font-size: toRem(18);
                font-weight: bold;
                color: #ff2626;
            }
        }
        .exchange {
            width: toRem(154);
            height: toRem(44);
            background: #ff5a01;
            border-radius: toRem(22);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: toRem(16);
            font-weight: bold;
        }
    }
    .success-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 100;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .modal-content {
            height: toRem(429);
            width: toRem(320);
            border-radius: toRem(12);
            background-color: #fff;
            .title {
                border-bottom-left-radius: 60% 40%;
                border-top-left-radius: toRem(12);
                border-top-right-radius: toRem(12);
                border-bottom-right-radius: 60% 40%;
                height: toRem(89);
                background: linear-gradient(271deg, #ff5a01 0%, #fc9c01 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: toRem(22);
                color: #fff;
                font-weight: bold;
                img {
                    width: toRem(22);
                    height: toRem(22);
                    margin-right: toRem(6);
                }
            }
            .goods-img {
                height: toRem(227);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: toRem(180);
                    height: toRem(180);
                }
            }
            .btns {
                div{
                    width: toRem(280);
                    height: toRem(46);
                    margin:0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: toRem(16);
                }
                div:nth-child(1) {
                    background: linear-gradient(270deg, #ff5a01 0%, #fc9a01 100%);
                    border-radius: toRem(23);
                    color: #fff;
                    margin-bottom: toRem(10);
                }
                div:nth-child(2){
                    color: #FF5A01;
                }
            }
        }
    }
}
