@function toRem($a) {
    @return ($a/37.5) + rem;
}

.integral-details {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y: scroll;
    background-color: #fff;
    padding-bottom: toRem(30);
    .integral-info {
        background-size: 100% 100%;
        height: toRem(278);
        overflow: hidden;
        padding-left: toRem(30);
        color: #fff;
        font-weight: bold;
        div:nth-child(1) {
            margin-top: toRem(25);
            font-size: toRem(18);
        }
        div:nth-child(2) {
            font-size: toRem(36);
            margin-top: toRem(5);
        }
    }
    .integral-list {
        background-color: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
        border-radius: toRem(8);
        width: toRem(345);
        box-sizing: border-box;
        margin: 0 auto;
        padding-top: toRem(68);
        margin-top: toRem(-156);
        padding: toRem(22) toRem(15);
        position: relative;
        img{
            position: absolute;
            width: toRem(140);
            height: toRem(93.67);
            right:toRem(40);
            top:toRem(-84);
        }
        .title {
            color: #fa6d31;
            font-size: toRem(16);
            font-weight: bold;
            margin-bottom: toRem(24);
            text-align: center;
        }
        .tabs {
            display: flex;
            margin-bottom: toRem(10);
            div {
                width: toRem(49);
                height: toRem(34);
                background: #f6f6f6;
                border-radius: toRem(4);
                color: #999;
                display: flex;
                align-items: center;
                justify-content: center;
                flex:1;
                margin-right: toRem(11);
                &:last-child{
                    margin:0;
                }
            }
            .active{
                font-weight: bold;
                color: #FF5A01;
                font-size: toRem(15);
                background:#FFF2EB;
            }
        }
        .list{
            .item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: toRem(67);
                border-bottom:toRem(1) solid #F6F6F6;
                div:nth-child(1){
                    p:nth-child(1){
                        font-weight: bold;
                    }
                    p:nth-child(2){
                        font-size: toRem(12);
                        color: #999;
                    }
                }
                div:nth-child(2){
                    font-size: toRem(18);
                    font-weight: bold;
                    color: #FA6D31;
                }
            }
            .no{
                margin-top: toRem(20);
                text-align: center;
                color: #ccc;
                font-size: toRem(12);
            }
        }
    }
}
