@function toRem($a) {
    @return ($a/37.5) + rem;
}

.settlement-index{
    font-size: toRem(14);
    color: #333;
    padding:toRem(12);
    .individual,.enterprise{
        background-size: 100% 100%;
        height: toRem(130);
        margin-bottom: toRem(12);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: toRem(25);
        div:nth-child(1){
            font-size: toRem(20);
            font-weight: bold;
            margin-bottom: toRem(5);
        }
        div:nth-child(3){
            margin-top: toRem(9);
            width: toRem(105);
            line-height: toRem(26);
            text-align: center;
            border-radius: toRem(13);
            background:#fff;
            font-size:toRem(13);
        }
    }
    .individual div:nth-child(3){
        box-shadow: 0px toRem(2) toRem(8) toRem(1) #FF6716;
        color: #FD8110;
    }
    .enterprise div:nth-child(3){
        box-shadow: 0px toRem(2) toRem(8) toRem(1) #1F5AEB;
        color: #1F5AEB;
    }
}