@function toRem($a) {
    @return ($a/37.5) + rem;
}

.content {
    font-size: toRem(14);
    color: #333;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: toRem(80);
    .integral-info {
        height: toRem(278);
        background-size: 100% 100%;
        overflow: hidden;
        padding-left: toRem(30);
        div {
            color: #fff;
            &:nth-child(1) {
                margin-top: toRem(25);
                font-size: toRem(18);
                font-weight: bold;
                margin-bottom: toRem(7);
            }
            &:nth-child(2) {
                font-weight: bold;
                font-size: toRem(36);
                margin-bottom: toRem(7);
            }
            &:nth-child(3) {
                font-size: toRem(12);
                width: toRem(78);
                height: toRem(25);
                border-radius: toRem(20);
                text-align: center;
                line-height: toRem(25);
                box-sizing: border-box;
                border: toRem(1) solid #fff;
            }
        }
    }
    .integral-task {
        width: toRem(345);
        box-sizing: border-box;
        padding: toRem(15);
        margin: 0 auto;
        background: #fff;
        border-radius: toRem(8);
        margin-top: toRem(-120);
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
        margin-bottom: toRem(10);
        .title {
            font-size: toRem(16);
            font-weight: bold;
            margin-bottom: toRem(15);
        }
        .task-list {
            .item {
                display: flex;
                margin-bottom: toRem(18);
                justify-content: space-between;
                align-items: center;
                position: relative;
                .left {
                    display: flex;
                    align-items: center;
                    .icon {
                        width: toRem(44);
                        height: toRem(44);
                        margin-right: toRem(10);
                    }
                    .name {
                        div:nth-child(1) {
                            font-weight: bold;
                            margin-bottom: toRem(5);
                        }
                        div:nth-child(2) {
                            display: flex;
                            align-items: center;
                            font-size: toRem(13);
                            color: #fa6d31;
                            img {
                                width: toRem(13);
                                height: toRem(13);
                                margin-right: toRem(4);
                            }
                        }
                    }
                }
                .right {
                    width: toRem(68);
                    height: toRem(28);
                    border-radius: toRem(14);
                    border: toRem(1) solid #ff6c00;
                    display: flex;
                    align-items: center;
                    color: #ff5a01;
                    justify-content: center;
                }
            }
        }
        .refresh {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: toRem(12);
            color: #999;
            img {
                width: toRem(12);
                height: toRem(12);
                margin-right: toRem(4);
            }
        }
        .hand {
            position: fixed;
            top: toRem(10);
            right: toRem(5);
            width: toRem(40);
            height: toRem(40);
            z-index: 10;
            animation: move 1s infinite alternate;
        }
        @keyframes move {
            from {
                top: toRem(10);
            }
            to {
                top: toRem(20);
            }
        }
    }
    .goods {
        width: toRem(345);
        margin: 0 auto;
        background: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
        border-radius: toRem(8);
        padding: toRem(15);
        box-sizing: border-box;
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: toRem(10);
            span:nth-child(1) {
                font-size: toRem(16);
                font-weight: bold;
            }
            span:nth-child(2) {
                color: #fa6d31;
                font-size: toRem(14);
            }
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            .item {
                width: toRem(150);
                margin-right: toRem(15);
                margin-bottom: toRem(15);
                img {
                    width: 100%;
                    height: toRem(150);
                }
                div:nth-child(2) {
                    margin-top: toRem(5);
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                div:nth-child(3) {
                    color: #ff2626;
                    margin-bottom: toRem(5);
                    span:nth-child(1) {
                        font-size: toRem(16);
                    }
                }
                div:nth-child(4) {
                    margin: 0 auto;
                    width: toRem(80);
                    height: toRem(30);
                    background: linear-gradient(90deg, #ff9700 0%, #ff6000 100%);
                    border-radius: toRem(16);
                    color: #fff;
                    font-weight: bold;
                    text-align: center;
                    line-height: toRem(30);
                }
                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
    }
    .no {
        margin-top: toRem(15);
        color: #ccc;
        font-size: toRem(12);
        text-align: center;
    }
}
