@function toRem($a) {
    @return ($a/37.5) + rem;
}

.enterprise-order {
    font-size: toRem(14);
    color: #333;
    height: 100%;
    overflow-y: scroll;
    .tabs {
        display: flex;
        padding: 0 toRem(14);
        overflow-x: scroll;
        height: toRem(48);
        align-items: center;
        margin-bottom: toRem(8);
        background-color: #fff;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 3;
        div {
            flex: none;
            margin-left: toRem(20);
            color: #999;
            &:first-child {
                margin: 0;
            }
        }
        .active {
            color: #ff1607;
            font-weight: bold;
            font-size: toRem(15);
        }
    }
    .list {
        padding: 0 toRem(14);
        .item {
            background-color: #fff;
            border-radius: toRem(8);
            margin-bottom: toRem(8);
            padding: toRem(14);
            box-sizing: border-box;
            .order-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: toRem(14);
                .store {
                    display: flex;
                    align-items: center;
                    font-size: toRem(15);
                    font-weight: bold;
                    img:nth-child(1) {
                        width: toRem(20);
                        height: toRem(20);
                        margin-right: toRem(5);
                    }
                    img:nth-child(2) {
                        width: toRem(6.31);
                        height: toRem(11);
                        margin-left: toRem(5);
                    }
                }
                .status {
                    color: #ff9100;
                    font-weight: bold;
                }
            }
            .order-center {
                display: flex;
                margin-bottom: toRem(10);
                img {
                    width: toRem(80);
                    height: toRem(80);
                    border-radius: toRem(5);
                    flex: none;
                }
                .goods-con {
                    margin-left: toRem(14);
                    flex: 1;
                    div:nth-child(1) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: toRem(8);
                        span:nth-child(1){
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: toRem(170);
                        }
                        span:nth-child(2) {
                            font-weight: bold;
                        }
                    }
                    div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #999;
                        font-size: toRem(12);
                    }
                }
            }
            .order-footer{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span:nth-child(1){
                    font-size: toRem(16);
                    font-weight: bold;
                    color: #ff1607;
                }
            }
            .btn{
                display: flex;
                justify-content: flex-end;
                div{
                    width: toRem(80);
                    height: toRem(30);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    margin-left: toRem(12);
                    border-radius: toRem(14);
                    margin-top:toRem(20)
                }
                .pay{
                    color: #fff;
                    background-color: #ff1607;
                }
                .cancel{
                    border:toRem(1) solid #E6E6E6;
                }
            }
        }
    }
}
