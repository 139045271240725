@function toRem($a) {
    @return ($a/37.5)+rem;
}

.index{
    font-size: toRem(14);
    color: #333;
    overflow:hidden;
    min-height: 100vh;
    background-color: #fff;
    .content{
		width: toRem(343);
		height:  toRem(54);
		border-bottom:  toRem(1) solid #f5f5f5;
		margin-left:  toRem(16);
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #333333;
	}
	.left{
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #999999;
        flex-shrink: 0;
        span{
            color: #FF7373;
        }
	}
    .right{
        div{
            text-align: right;
        }
    }
	.logisticsIcon{
		width: toRem(5);
		height:toRem(10);
		margin-left: toRem(9);
	}
	input{
		flex: 1;
		text-align: right;
		color: #000000;
        font-size: toRem(14);
	}
	.footer{
		width:  toRem(343);
		height:  toRem(44);
		background: #FF982E;
		border-radius:  toRem(22);
		margin:  toRem(150) toRem(16) 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size:  toRem(18);
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
	}
}